import store from './index'

export default {
  namespaced: true,

  state: () => ({
    searchString: '',
  }),

  mutations: {
    setSearchString: (state, value) => (state.searchString = value),
  },

  getters: {
    allActiveFeatures: () => store.getters['layers/allActiveFeatures'],
    searchVariants: (state, getters) =>
      state.searchString?.length
        ? getters.allActiveFeatures.filter(feature =>
            feature.title.toLowerCase().includes(state.searchString.toLowerCase())
          )
        : getters.allActiveFeatures,
  },
}
