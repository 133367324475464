<template>
  <b-sidebar
    class="m-sidebar"
    :class="{
      'is-left': !right,
      'is-right': right,
    }"
    type="is-light"
    fullheight
    :right="right"
    v-model="isOpened"
    :can-cancel="[]"
  >
    <m-button
      v-if="backButton"
      class="m-sidebar__close-btn is-hidden-mobile"
      @click="isOpened = false"
      :icon="right ? 'caret-right' : 'caret-left'"
    >
    </m-button>

    <div class="m-sidebar__inner">
      <slot />
    </div>
  </b-sidebar>
</template>

<script>
import MCheckbox from '@/components/MCheckbox.vue'

export default {
  name: 'MSidebar',

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    right: {
      type: Boolean,
      default: false,
    },

    searchString: {
      type: String,
      default: '',
    },

    backButton: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({}),

  computed: {
    isOpened: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },

  components: {
    MCheckbox,
  },
}
</script>

<style lang="scss">
.m-sidebar.b-sidebar {
  $self: '.m-sidebar';
  position: relative;
  overflow: visible;

  &.is-left {
    #{$self}__close-btn {
      left: calc(100% + 8px);

      @include mobile {
        left: calc(100% - 100px);
      }
    }
  }

  .sidebar-content {
    overflow: visible;
    padding: 20px;
    width: 412px;
    background-color: $white;
    z-index: $layers-panel-index;
    max-width: 100vw;
    border: 1px solid $border-color;

    @include mobile {
      padding: 10px;
    }
  }

  #{$self}__close-btn {
    position: absolute;
    top: 20px;
    left: -48px;
    width: 40px;
    height: 48px;
    background: #f4f7fb;
  }

  // TODO: Кнопка справа

  #{$self}__inner {
    overflow: auto;
  }

  #{$self}__tools {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #{$self}__settings {
    display: flex;
    align-items: center;
    color: $black;
    cursor: pointer;
    user-select: none;

    * {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  #{$self}__reset {
    cursor: pointer;
    user-select: none;
  }

  #{$self}__all-layers-toggle {
    .b-checkbox.checkbox input[type='checkbox'] + .check {
      width: 16px;
      height: 16px;
    }

    .control-label {
      color: $black;
    }
  }
}
</style>
