import axios from 'axios'
import store from './index'

export default {
  namespaced: true,

  state: () => ({
    table: {},
  }),

  mutations: {
    setTable: (state, value) => (state.table = value),
  },

  getters: {
    layers: () => store.state.layers.layers,
  },

  actions: {
    async getTableData({ state, commit }) {
      const responses = state.layers.map(layer =>
        axios.get(`/maps/maps.items/${layer.item['mapware.layers']}/~data-items.json`, {
          params: { uid: state.token },
        })
      )
      const result = []
      axios
        .all(responses)
        .then(
          axios.spread((...responses) => {
            result.push(responses.reduce((acc, { data }) => acc.push(data) && acc, []))
          })
        )
        .then(() => {
          commit('setTable', result)
        })
    },
  },
}
