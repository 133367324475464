<template>
  <div
    class="m-collapse"
    :class="{
      background,
    }"
  >
    <slot name="title-prepend"></slot>

    <b-collapse
      v-bind="$attrs"
      :open.sync="$value"
      class="m-collapse__wrapper"
      :class="{ 'mb-0': noMb }"
      animation="slide"
      @open="$emit('open')"
    >
      <template #trigger="{ open }">
        <div
          class="m-collapse__trigger-header card-header"
          role="button"
          :aria-expanded="open"
        >
          <div
            class="card-header-title"
            :class="{
              'title-prepend': !!$slots['title-prepend'],
            }"
          >
            {{ title }}
          </div>
          <a class="card-header-icon">
            <svg-icon :name="!open ? 'chevron-down' : 'chevron-up'" />
          </a>
        </div>
      </template>

      <div class="card-content">
        <slot />
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: 'MCollapse',

  props: {
    background: {
      type: Boolean,
      default: false,
    },

    value: {
      default: false,
    },

    title: {
      type: String,
    },

    noMb: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    $value: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style lang="scss">
.m-collapse {
  $self: &;

  display: flex;
  position: relative;
  width: 100%;

  &.background {
    #{$self}__wrapper {
      background-color: #f4f7fb;
    }
  }

  &__wrapper {
    padding: 0 12px;
    flex-grow: 1;
    border-radius: 2px;
  }

  &__group-toggle {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 10;
  }

  .card-header {
    width: 100%;
    min-height: 40px;
    padding: 0;
    box-shadow: none;
    border-radius: 2px;
    color: $button-text;

    &-title {
      padding: 0;
      font-size: 16px;
      line-height: 1.4;
      font-weight: 400;
      color: #000;

      &.title-prepend {
        padding-left: 31px;
      }
    }

    &-icon {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .card-content {
    padding: 0;
  }

  .m-collapse {
    #{$self}__group-toggle {
      left: 0;
      top: 12px;
    }

    #{$self}__wrapper {
      padding: 0;
      margin-bottom: 10px;
    }

    .card-header-title {
      font-size: 12px;
      user-select: none;
    }
  }
}
</style>
