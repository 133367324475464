<template>
  <l-tile-layer
    :url="url"
    visible
    subdomains="0123456789abcdefghijklmnopqrstuvwxyz"
    @ready="ready($event)"
    v-bind="$attrs"
  />
</template>

<script>
import { LTileLayer } from 'vue2-leaflet'

export default {
  name: 'MTileLayer',

  props: {
    baseUrl: String,
    transparent: {
      type: Boolean,
      default: true,
    },
    bbox: String,
  },

  computed: {
    _bbox() {
      const arr = this.bbox.split(',').map(item => item * 100000)
      return arr.join(',')
    },
    url() {
      const params = {
        service: 'WMS',
        request: 'GetMap',
        layers: '',
        styles: '',
        format: 'image/png',
        transparent: true,
        version: '1.1.1',
        rand: Math.random().toString(),
        width: 256,
        height: 256,
        srs: 'EPSG:3857',
      }

      return `https://pprgis.krasnodar.ru${
        this.baseUrl
      }/-_rand/${Math.random().toString()}.tile?${this.serialize(params)}&bbox=${this._bbox}`
    },
  },

  methods: {
    serialize(obj) {
      const str = []
      for (let p in obj)
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
        }
      return str.join('&')
    },

    ready(event) {},
  },

  components: {
    'l-tile-layer': LTileLayer,
  },
}
</script>
