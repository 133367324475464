<template>
  <div
    class="m-layers-section"
    v-if="showInSearch"
  >
    <m-collapse
      :title="title"
      background
      :value="openAll"
    >
      <template #title-prepend>
        <m-checkbox
          class="m-collapse__group-toggle mr-0"
          :value="isAllItemsActive"
          @input="groupSetActive($event)"
          :indeterminate="isGroupIndeterminate"
          :disabled="shouldDisableGroup"
        />
      </template>

      <div class="card-content">
        <m-separator class="mb-3" />
        <div
          v-for="(item, index) in items"
          :key="`second-${item.item['mapware.layers']}`"
          class="d-flex mb-2"
        >
          <m-collapse
            v-if="false && item.features"
            :title="item.item.title.replace('__.geojson', '')"
          >
            <template #title-prepend>
              <m-checkbox
                class="m-collapse__group-toggle mr-0"
                @input="setAllFeaturesActive(item, $event)"
                :value="item.features.length === item.features.filter(el => el.active).length"
              />
            </template>

            <m-checkbox
              class="m-layers-section__third-checkbox mb-1 ml-5"
              v-for="feature in item.features"
              :key="feature.id"
              v-model="feature.active"
            >
              {{ feature.properties.title }}
            </m-checkbox>
          </m-collapse>

          <template v-if="item.showInSearch">
            <template v-if="item.item.iconClass">
              <div class="m-layers-section__row">
                <m-checkbox
                  class="m-layers-section__third-checkbox mb-1 ml-3"
                  v-model="item.active"
                  :disabled="item.isError"
                >
                  {{ item.item.title }} {{ item.isError ? '(нет доступа)' : '' }}
                </m-checkbox>
                <img
                  class="m-layers-section__layer-image"
                  :src="`https://pprgis.krasnodar.ru${item.item.iconClass.replace(
                    /^feature\:\/\//,
                    ''
                  )}`"
                  alt=""
                />
              </div>
            </template>
            <m-checkbox
              v-else
              v-model="item.active"
              class="m-layers-section__third-checkbox mb-1 ml-3"
              :disabled="item.isError"
            >
              {{ item.item.title }} {{ item.isError ? '(нет доступа)' : '' }}
            </m-checkbox>
          </template>
        </div>
      </div>
    </m-collapse>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MSeparator from './MSeparator.vue'
import MCollapse from './MCollapse.vue'
import MCheckbox from './MCheckbox.vue'

export default {
  name: 'MLayersSection',

  props: {
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    openAll: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isCollapseOpened: false,
  }),

  computed: {
    showInSearch() {
      return this.items.find(item => item.showInSearch)
    },

    shouldDisableGroup() {
      return this.items.every(item => item.isError)
    },

    isAllItemsActive() {
      return this.items.filter(item => item.active)?.length === this.items.length
    },

    isGroupIndeterminate() {
      return !!(this.items.find(item => item.active) && !this.items.every(item => item.active))
    },
  },

  methods: {
    layerInputHandler(item, value) {
      item.active = value
    },

    groupSetActive(value) {
      this.items.forEach(item => {
        if (!item.isError) {
          item.active = value
        }

        item?.features?.forEach(feature => {
          feature.active = value
        })
      })
    },

    setAllFeaturesActive(item, value) {
      item.active = value
      // item.features.forEach(feature => {
      //   feature.active = value
      // })
    },
  },

  components: {
    MSeparator,
    MCollapse,
    MCheckbox,
  },
}
</script>

<style lang="scss">
.m-layers-section {
  $self: &;

  &__third-checkbox {
    font-size: 12px;
    color: $black;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__layer-image {
    max-width: 18px;
  }
}
</style>
