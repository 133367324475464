export default {
  namespaced: true,

  state: () => ({
    route: '',
    topLinks: [],
    bottomLinks: [],
  }),

  mutations: {
    setTopLinks: (state, value) => (state.topLinks = value),
    setBottomLinks: (state, value) => (state.bottomLinks = value),
    setRoute: (state, value) => (state.route = value),
  },
}
