<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__wrapper">
        <div class="footer__top">
          <div class="footer__start">
            <svg-icon
              name="logo"
              class="footer__logo"
            />
            <h3>Публичный портал<br />Региональной<br />геоинформационной системы</h3>
            <h4>Краснодарского края</h4>
          </div>
          <div class="footer__end">
            <div class="footer__sides">
              <div
                v-if="false"
                class="footer__side footer__side--left"
              >
                <div
                  class="footer__section"
                  v-for="(section, index) in sections.left"
                  :key="index"
                >
                  <a
                    class="footer__section-title"
                    href="#"
                    >{{ section.title }}</a
                  >
                  <ul v-if="section.links">
                    <li v-for="(link, linkIndex) in section.links">
                      <a
                        class="footer__section-link"
                        href="#"
                        >{{ link.title }}</a
                      >
                    </li>
                  </ul>
                </div>
              </div>

              <div class="footer__side footer__side--right">
                <div
                  class="footer__section"
                  v-for="(section, index) in sections"
                  :key="index"
                >
                  <div
                    class="footer__section-title"
                    href="#"
                  >
                    {{ section.name }}
                  </div>

                  <ul v-if="section.links">
                    <li v-for="(link, linkIndex) in section.links">
                      <a
                        class="footer__section-link"
                        :href="link.link"
                        >{{ link.name }}</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="footer__bottom">
          <div class="footer__bottom-start">
            <svg-icon
              name="cc"
              class="footer__cc"
            />
            <p class="footer__description">
              Все материалы сайта доступны по лицензии: Creative Commons «Attribution» 4.0 Всемирная
            </p>
          </div>

          <div
            class="footer__socials"
            v-if="false"
          >
            <a
              v-for="(link, index) in socials"
              :href="link.url"
              :key="index"
              class="footer__social-link"
            >
              <svg-icon
                class="footer__social-icon"
                :name="link.name"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'DFooter',

  data: () => ({
    // sections: {
    //   left: [
    //     {
    //       title: 'Материалы по бюджету',
    //       links: [
    //         { title: 'Бюджет в картинках' },
    //         { title: 'Публичные слушания' },
    //         { title: 'Общественное обсуждение (опросы) ' },
    //       ],
    //     },
    //     {
    //       title: 'Противодействие коррупции',
    //       links: [{ title: 'Горячая линия министерства' }],
    //     },
    //   ],
    //   right: [
    //     {
    //       title: 'Путеводитель по сайту',
    //       links: [
    //         { title: 'Версии для мобильных устройств' },
    //         { title: 'Обращение в министерство ' },
    //         { title: 'О сайте' },
    //         { title: 'Карта сайта' },
    //         { title: 'RSS Контакты' },
    //         { title: 'Реквизиты' },
    //         { title: 'Статистика посещаемости сайта' },
    //       ],
    //     },
    //     {
    //       title: 'Старая версия сайта',
    //     },
    //   ],
    // },

    socials: [
      { name: 'telegram', url: '#' },
      { name: 'instagram', url: '#' },
      { name: 'youtube', url: '#' },
      { name: 'vk', url: '#' },
      { name: 'facebook', url: '#' },
      { name: 'twitter', url: '#' },
      { name: 'ok', url: '#' },
    ],
  }),

  computed: {
    sections() {
      return this.$store.state.main.bottomLinks
    },
  },
}
</script>

<style lang="scss">
.footer {
  $self: &;

  position: relative;
  padding: 0;
  width: 100%;
  color: $white;
  background-color: #002b68;
  background-image: url('/images/footer-pattern.svg');
  background-repeat: no-repeat;
  background-position: 100%;

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 262px;
    height: 100%;
    background-color: #002b68;
    background-image: url('/images/footer-left-pattern.svg');
    background-repeat: no-repeat;
    z-index: 0;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    padding: 85px 0 70px;
    border-bottom: 1px solid rgba($white, 0.2);

    @include tablet {
      padding: 64px 0 48px;
    }

    @include mobile {
      padding: 48px 0 32px;
      flex-direction: column;
    }
  }

  &__bottom {
    padding: 48px 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include tablet {
      padding: 24px 0 24px;
    }

    @include mobile {
      flex-direction: column;
    }

    &-start {
      display: flex;
      align-items: center;

      @include mobile {
        order: 1;
      }
    }
  }

  &__start {
    @include mobile {
      margin-bottom: 32px;
    }

    h3 {
      font-size: 24px;
      line-height: 29px;
      font-weight: 600;
      margin-bottom: 11px;

      @include tablet {
        font-size: 18px;
        line-height: 22px;
      }
    }

    h4 {
      font-size: 16px;
      line-height: 20px;
      color: #dde1e6;

      @include tablet {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  #{$self}__logo {
    width: 65px;
    height: 75px;
    margin-bottom: 19px;

    @include tablet {
      width: 42px;
      height: 45px;
      margin-bottom: 10px;
    }
  }

  &__sides {
    display: flex;
    gap: 100px;

    @include tablet {
      gap: 16px;
    }

    @media (max-width: 374px) {
      flex-direction: column;
    }
  }

  &__section {
    &:not(:last-child) {
      margin-bottom: 40px;
    }

    &-title {
      font-size: 20px;
      line-height: 26px;
      font-weight: 500;
      margin-bottom: 22px;
      color: $white;

      &:hover {
        color: rgba($white, 0.7);
      }

      @include tablet {
        font-size: 16px;
        line-height: 20px;
      }
    }

    &-link {
      font-size: 16px;
      line-height: 20px;
      color: #d1d1d1;

      &:hover {
        color: rgba(#d1d1d1, 0.7);
      }

      @include tablet {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  &__cc {
    color: #dde1e6;
    margin-right: 16px;
  }

  &__description {
    font-size: 16px;
    line-height: 20px;
    color: #dde1e6;

    @include tablet {
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__socials {
    display: flex;
    align-items: center;

    @include mobile {
      margin-bottom: 16px;
    }
  }

  &__social {
    &-icon {
      margin-right: 16px;
    }
  }

  .container {
    @include tablet {
      padding: 0 15px;
    }
  }
}
</style>
