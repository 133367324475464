import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/Index.vue'
import RGIS from '@/views/RGIS.vue'
import store from '@/store'

Vue.use(VueRouter)

const idToReroute = '8f48d53c-545d-48c3-a500-b3f6037ea33e'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
  },
  {
    path: '/widget',
    redirect: to => ({
      name: 'Map',
      params: {
        widgetId: idToReroute,
      },
    }),
  },
  {
    path: '/widget/:widgetId/',
    name: 'Map',
    component: RGIS,
    props: {
      widgetId: idToReroute,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  store.commit('main/setRoute', to)
  next()
})

export default router
