<template>
  <b-button
    @click="$emit('click')"
    class="m-button"
    :class="{
      'not-active': toggle && !active,
      active,
      'with-icon': icon,
      'is-fluid': fluid,
      'align-center': alignCenter,
      'm-button--icon': icon,
      [`m-button--type-${type}`]: type,
    }"
    :title="title"
    v-bind="$attrs"
  >
    <svg-icon
      v-if="icon"
      :name="icon"
    />
    <slot v-else />
  </b-button>
</template>

<script>
export default {
  name: 'MButton',

  props: {
    toggle: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    fluid: {
      type: Boolean,
      default: false,
    },
    alignCenter: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'default',
    },
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
.m-button {
  width: 44px;
  height: 44px;
  border-radius: 8px;
  border: 1px solid $border-color;
  color: $button-text;
  box-shadow: unset;

  &:not(.m-button--icon) {
    width: auto;
  }

  &:focus {
    &:not(:active) {
      box-shadow: unset;
      border-color: $border-color;
    }
  }

  &.active,
  &:active {
    color: $active-color;
    box-shadow: unset;
    border-color: $active-color;

    &:not(:active) {
      border-color: $active-color;
      box-shadow: unset;
    }
  }

  &--type {
    &-primary {
      background: $primary-color;
      color: $white;
    }
  }

  &.not-active {
    border: 1px solid $border-color;
    color: $button-text;

    &:not(:active) {
      border: 1px solid $border-color;
      color: $button-text;
    }
  }

  &.with-icon {
    > span {
      height: 24px;
    }
  }

  &.is-fluid {
    width: unset;
  }

  &.align-center {
    > span {
      display: flex;
      align-items: center;
    }
  }
}
</style>
