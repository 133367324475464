<template>
  <div class="m-layers-sections">
    <m-select-layer-section
      v-for="(item, index) in items"
      :key="index"
      :item="item"
      :title="item.title"
      :openAll="openAll"
    />
  </div>
</template>

<script>
import MSelectLayerSection from './MSelectLayerSection.vue'

export default {
  name: 'MSelectLayerSections',

  props: {
    items: {
      type: Array,
      required: true,
    },
    openAll: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    MSelectLayerSection,
  },
}
</script>
