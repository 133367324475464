<template>
  <b-checkbox
    v-bind="$attrs"
    class="m-checkbox"
    :value="$value"
    :indeterminate="indeterminate"
    @input="$emit('input', $event)"
  >
    <slot />
  </b-checkbox>
</template>

<script>
export default {
  name: 'MCheckbox',

  props: {
    value: [String, Number, Boolean],
    indeterminate: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    $value: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style lang="scss">
.m-checkbox {
  &.b-checkbox.checkbox {
    input[type='checkbox'] {
      &:focus {
        &:checked {
          + .check {
            box-shadow: unset;
          }
        }

        + .check {
          box-shadow: unset;
        }
      }

      + .check {
        width: 16px;
        height: 16px;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_191_5855)'%3E%3Cpath d='M0.8 0.75H15.2C15.2133 0.75 15.226 0.755268 15.2354 0.764645C15.2447 0.77402 15.25 0.786738 15.25 0.8V15.2C15.25 15.2133 15.2447 15.226 15.2354 15.2354C15.226 15.2447 15.2133 15.25 15.2 15.25H0.8C0.786738 15.25 0.77402 15.2447 0.764645 15.2354C0.755268 15.226 0.75 15.2133 0.75 15.2V0.8C0.75 0.786739 0.755268 0.774021 0.764645 0.764645C0.774021 0.755268 0.786739 0.75 0.8 0.75Z' stroke='currentColor' stroke-width='1.5'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_191_5855'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
        background-position: center;
        background-size: cover;
        border-radius: 0;
        border: unset;
      }

      &:indeterminate {
        + .check {
          background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzExMDNfMzApIj4KPHBhdGggZD0iTTIyLjggMEgxLjJDMC44ODE3NDEgMCAwLjU3NjUxNiAwLjEyNjQyOCAwLjM1MTQ3MiAwLjM1MTQ3MkMwLjEyNjQyOCAwLjU3NjUxNiAwIDAuODgxNzQxIDAgMS4yVjIyLjhDMCAyMy4xMTgzIDAuMTI2NDI4IDIzLjQyMzYgMC4zNTE0NzIgMjMuNjQ4NkMwLjU3NjUxNiAyMy44NzM2IDAuODgxNzQxIDI0IDEuMiAyNEgyMi44QzIzLjExODMgMjQgMjMuNDIzNiAyMy44NzM2IDIzLjY0ODYgMjMuNjQ4NkMyMy44NzM2IDIzLjQyMzYgMjQgMjMuMTE4MyAyNCAyMi44VjEuMkMyNCAwLjg4MTc0MSAyMy44NzM2IDAuNTc2NTE2IDIzLjY0ODYgMC4zNTE0NzJDMjMuNDIzNiAwLjEyNjQyOCAyMy4xMTgzIDAgMjIuOCAwWiIgZmlsbD0iIzRGNEY0RiIvPgo8cmVjdCB4PSI1IiB5PSIxMCIgd2lkdGg9IjE1IiBoZWlnaHQ9IjQiIHJ4PSIxIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzExMDNfMzAiPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==');
          background-position: center;
          background-size: cover;
          border-radius: 0;
          border: unset;
        }
      }

      &:checked {
        + .check {
          background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_191_5872)'%3E%3Cpath d='M15.2 0H0.8C0.587827 0 0.384344 0.0842854 0.234315 0.234315C0.0842854 0.384344 0 0.587827 0 0.8V15.2C0 15.4122 0.0842854 15.6157 0.234315 15.7657C0.384344 15.9157 0.587827 16 0.8 16H15.2C15.4122 16 15.6157 15.9157 15.7657 15.7657C15.9157 15.6157 16 15.4122 16 15.2V0.8C16 0.587827 15.9157 0.384344 15.7657 0.234315C15.6157 0.0842854 15.4122 0 15.2 0Z' fill='%234F4F4F'/%3E%3Cpath d='M6.78076 10.5995C6.67931 10.555 6.58724 10.4899 6.50985 10.4078L4.91148 8.7263C4.75472 8.56139 4.66666 8.33773 4.66666 8.10451C4.66666 7.87129 4.75472 7.64762 4.91148 7.48271C5.06824 7.3178 5.28085 7.22516 5.50254 7.22516C5.72424 7.22516 5.93685 7.3178 6.09361 7.48271L7.10091 8.55115L9.90637 5.59105C10.0631 5.42614 10.2757 5.3335 10.4974 5.3335C10.7191 5.3335 10.9317 5.42614 11.0885 5.59105C11.2453 5.75596 11.3333 5.97963 11.3333 6.21285C11.3333 6.44607 11.2453 6.66973 11.0885 6.83464L7.69197 10.4078C7.61458 10.4899 7.52251 10.555 7.42106 10.5995C7.31962 10.6439 7.21081 10.6668 7.10091 10.6668C6.99101 10.6668 6.8822 10.6439 6.78076 10.5995Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_191_5872'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
          background-position: center;
          background-size: cover;
          border-radius: 0;
          border: unset;
        }
      }
    }
  }
}
</style>
