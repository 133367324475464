<template>
  <b-field
    class="m-field"
    v-bind="$attrs"
  >
    <slot />
  </b-field>
</template>

<script>
export default {
  name: 'MField',
}
</script>

<style lang="scss">
.m-field {
  .label {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    color: $label-color;
    margin-bottom: 4px;
  }
}
</style>
