<template>
  <m-field :label="label">
    <b-select
      class="m-select"
      :class="{ fluid }"
      v-bind="$attrs"
      v-model="model"
    >
      <option
        v-for="option in options"
        :value="option.id"
        :key="option.id"
      >
        {{ option.text }}
      </option>
    </b-select>
  </m-field>
</template>

<script>
export default {
  name: 'MSelect',

  props: {
    value: {
      required: true,
    },
    options: {
      required: true,
    },
    fluid: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
    },
  },

  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style lang="scss">
.m-select {
  &.fluid {
    width: 100%;

    .select,
    select {
      width: 100%;
    }
  }

  select {
    background-color: #f4f7fb;
  }

  .select {
    &:not(.is-multiple):not(.is-loading) {
      &:after {
        border-color: $button-text;
      }
    }
  }
}
</style>
