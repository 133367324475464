import Vue from 'vue'
import Vuex from 'vuex'

import common from './common'
import dictionary from './dictionary'
import layers from './layers'
import table from './table'
import search from './search'
import filters from './filters'
import main from './main'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},

  mutations: {
    reset: state => {
      // state.replaceState(state, {})
    },
  },

  actions: {},

  modules: {
    dictionary,
    common,
    layers,
    table,
    search,
    filters,
    main,
  },
})

export { common, dictionary, layers, table, search, filters, main }
