<template>
  <div class="m-taginput-wrapper">
    <b-taginput
      v-bind="$attrs"
      class="m-taginput"
      :class="{
        bg,
      }"
      :autocomplete="false"
      :placeholder="placeholder"
      :icon-right="iconRight"
      @click.native="$emit('click')"
      :value="$value"
      @input="$emit('input', $event[0])"
      ref="component"
      :before-adding="beforeAdding"
    />
  </div>
</template>

<script>
export default {
  name: 'MTaginput',

  props: {
    value: {
      type: String,
    },
    placeholder: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
    bg: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    $value: {
      get() {
        return []
      },
      set(value) {
        this.$emit('input', value[0])
      },
    },
  },

  methods: {
    // focus() {
    //   this.$refs.component.focus()
    // },

    beforeAdding(value) {
      this.$emit('input', value)
      return false
      // return false
    },
  },
}
</script>

<style lang="scss">
.m-taginput {
  &.taginput {
    &.bg {
      .taginput-container {
        background-color: $bg;

        .input {
          background: $bg;
        }

        > .tag {
          margin-left: 8px;
        }
      }
    }

    .taginput-container {
      border-radius: 8px;
      &.is-focusable {
        &:active,
        &:focus {
          box-shadow: unset;
          border-color: darken($border-color, 33%);
        }

        &.is-focused {
          box-shadow: unset;
          border-color: unset;
        }
      }

      .input {
        &::placeholder {
          font-size: 16px;
          line-height: 19px;
          font-weight: 500;
          color: #828282;
        }
      }

      .tag {
        &:not(body) {
          padding: 12px 6px;
          font-size: 12px;
          line-height: 140%;
          color: $black;
          background-color: $bg;
          border: 1px solid $border-color;
          border-radius: 8px;

          .delete {
            background-color: unset;

            &:before {
              background-color: #9ca3af;
              height: 1px;
            }

            &:after {
              width: 1px;
              background-color: #9ca3af;
            }
          }
        }
      }

      .control {
        &.has-icons-right {
          .icon {
            width: 20px;
            height: 20px;
            top: calc(50% - 10px);
            right: 14px;
            color: $button-text;
          }
        }
      }
    }
  }
}
</style>
