import '@/scss/main.scss'

import Vue from 'vue'
import App from '@/App.vue'
import store from './store'
import customComponents from '@/utils/components'
import SvgSprite from 'vue-svg-sprite'
import Buefy from 'buefy'
import leaflet from '@/utils/leaflet'
import axios from '@/plugins/axios'
import router from './router'
import '@/plugins/smooth-wheel-zoom'

Vue.config.productionTip = false

Vue.use(SvgSprite)
Vue.use(Buefy) // TODO: Change to using only individual components

leaflet()
axios()
customComponents(Vue)

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
