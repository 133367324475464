import axios from 'axios'
import store from './index'

export default {
  namespaced: true,

  state: () => ({
    layers: [],
    mapLayers: [],
    enabledUs: [],
    enabledFilters: {},
    table: {},
    selectedLayer: {},
    searchString: '',
    urls: '',
    infoClickData: [],
    headersLibrary: {},
  }),

  mutations: {
    setLayers: (state, value) => (state.layers = value),
    addLayer: (state, value) => state.layers.push(value),
    setMapLayers: (state, value) => (state.mapLayers = value),
    setEnabledUs: (state, value) => (state.enabledUs = value),
    setEnabledFilters: (state, value) => (state.enabledFilters = value),
    addMapLayer: (state, value) => state.mapLayers.push(value),
    setTable: (state, value) => (state.table = value),
    setSelectedLayer: (state, value) => (state.selectedLayer = value),
    setSearchString: (state, value) => (state.searchString = value),
    setUrls: (state, value) => (state.urls = value),
    setInfoClickData: (state, value) => (state.infoClickData = value),
    addInfoClickData: (state, value) => state.infoClickData.push(value),
    setHeadersLibrary: (state, value) => (state.headersLibrary = value),
  },

  getters: {
    // check(_state, _getters, rootState, rootGetters) {}
    token: () => store.state.common?.token,

    activeMapLayers: state => state.mapLayers.filter(layer => layer.active),
    filteredMapLayers: (_state, getters) =>
      getters.activeMapLayers.map(layer => ({
        ...layer,
        features: layer.features?.filter(feature => !feature.isLayerFiltered || feature.filterShow),
      })),
    activeSourceUrls: (_state, getters) =>
      getters.activeMapLayers.map(layer => `${layer.item.source_url}.json`),
    dictCats: () => store.state.dictionary.categories,

    groupedMapLayers: (state, getters) => {
      const result = []
      if (getters.dictCats && state.mapLayers) {
        state.mapLayers.forEach(layer => {
          const $category = getters.dictCats.find(
            dictCat => dictCat?.id === layer.item?.['mapware.categories']
          )

          const resultIndex = result.findIndex(cat => cat.id === layer.item?.['mapware.categories'])

          if (resultIndex !== -1) {
            result[resultIndex].items.push(layer)
          } else {
            result.push({
              ...$category,
              items: [layer],
            })
          }
        })
        return result
      }

      return []
    },

    groupedActiveMapLayers: (state, getters) => {
      const result = []
      if (getters.dictCats && state.mapLayers) {
        getters.activeMapLayers.forEach(layer => {
          const $category = getters.dictCats.find(
            dictCat => dictCat?.id === layer.item?.['mapware.categories']
          )

          const resultIndex = result.findIndex(cat => cat.id === layer.item?.['mapware.categories'])

          if (resultIndex !== -1) {
            result[resultIndex].items.push(layer)
          } else {
            result.push({
              ...$category,
              items: [layer],
            })
          }
        })
        return result
      }

      return []
    },

    selectedLayerData: state => {
      if (!state.selectedLayer.item) {
        return []
      }

      if (!state.selectedLayer?.headers) {
        return Object.entries(state.selectedLayer.item)
          .filter(([title, text]) => text)
          .map(([title, text]) => ({
            title,
            text,
          }))
      }

      return state.selectedLayer?.headers?.reduce((acc, header) => {
        const { item } = state.selectedLayer
        acc.push({
          title: header.title,
          text: item[header.name],
        })
        return acc
      }, [])
    },

    allFeatures: state => {
      return state.mapLayers.reduce((acc, layer) => {
        if (layer.features) {
          layer.features.forEach(feature => {
            const coordinates = feature?.geometry?.coordinates
            const icon = feature.iconClass
              ? `https://pprgis.krasnodar.ru${feature.iconClass.replace(/^feature\:\/\//, '')}`
              : ''
            acc.push({
              id: feature.id,
              title: feature.properties.title,
              layer: layer.item.title,
              layerId: layer?.item?.['mapware.layers'],
              coordinates: coordinates ? [...coordinates].reverse() : null,
              icon,
              u: feature?.properties?.u,
              properties: feature?.properties,
            })
          })
        }
        return acc
      }, [])
    },

    allActiveFeatures: (_state, getters) => {
      return getters.activeMapLayers.reduce((acc, layer) => {
        if (layer.features) {
          layer.features.forEach(feature => {
            const icon = feature.iconClass
              ? `https://pprgis.krasnodar.ru${feature.iconClass.replace(/^feature\:\/\//, '')}`
              : ''
            acc.push({
              // id: feature.id,
              ...feature,
              id: feature.properties?.['_maps_xid'] || feature.properties?.['dinv.objects2'],
              title: feature.properties.title,
              layer: layer.item.title,
              icon,
            })
          })
        }
        return acc
      }, [])
    },
  },

  actions: {
    async getLayer({ state, commit, getters }, url) {
      const { data } = await axios.get(`${url}.json`, { params: { uid: getters.token } })

      commit('addLayer', {
        ...data,
        active: true,
        showInSearch: true,
      })
    },

    async getLayers({ state, getters, commit, dispatch }, first = true) {
      const active = !!first

      if (!first) {
        commit(
          'setEnabledUs',
          getters.activeMapLayers.map(item => item.u)
        )
        commit(
          'setEnabledFilters',
          getters.activeMapLayers.reduce((acc, layer) => {
            if (layer.activeFilter) {
              acc[layer.u] = {
                filters: layer.filters,
              }
            }
            return acc
          }, {})
        )
      }

      const responses = state.urls.map(
        async url => await axios.get(`${url}.json`, { params: { uid: getters.token } })
      )

      const result = []

      await axios
        .all(responses)
        .then(res => {
          res.forEach(data => {
            result.push({
              ...data.data,
              filters: data.data.headers.map(el => ({
                ...el,
                active: true,
                activeFilter: false,
                showInSearch: true,
                value: '',
              })),
              active,
            })
            store.commit('dictionary/setLayerActive', data.data?.item['mapware.layers'])
          })
        })
        .then(() => {
          commit('setLayers', result)
          dispatch('getMapLayers', active)
        })
        .catch(() => {})
    },

    async getMapLayer({ commit }, url) {},

    async getMapLayers({ state, dispatch, commit }, first = true) {
      const active = !!first

      const responses = state.layers.map(layer => {
        const type = layer.item.source_protocol
        let url

        if (type === 'TileLayer') {
          const source = layer.item.url
          // url = `${layer.item.source_url}.json`
          if (source.slice(-1) === '/') {
            url = `${source.slice(0, source.length - 1)}.json`
          } else {
            url = `${source}.json`
          }
        } else if (layer.item.source_protocol === 'WmsLayer') {
          return {
            data: {
              ...layer,
              active,
              showInSearch: true,
            },
          }
        } else {
          url = `${layer.item.source_url}.geojson`.replace('.geojson.geojson', '.geojson')
        }
        return axios
          .get(url, {
            // params: { uid: state.token },
          })
          .catch(
            error =>
              new Promise(resolve => {
                resolve({
                  data: {
                    isError: error ? true : false,
                  },
                })
              })
          )
      })

      const result = []

      await axios
        .all(responses)
        .then(res => {
          res.forEach((data, index) => {
            if (typeof data?.data === 'object') {
              const isActive = first ? true : state.enabledUs.includes(data.data.u) ? true : false
              const isActiveFilter = state.enabledFilters[data.data.u] ? true : false
              result.push({
                ...data.data,
                active: isActive,
                activeFilter: isActiveFilter,
                showInSearch: true,
                isFiltered: false,
                item: state.layers?.[index]?.item,
                filters: isActiveFilter
                  ? state.enabledFilters[data.data.u].filters
                  : state.layers?.[index]?.filters,
                features: data?.data.features?.map(feature => ({
                  ...feature,
                  iconClass: state.layers?.[index]?.item?.iconClass,
                  layerId: state.layers?.[index]?.item?.['mapware.layers'],
                  active: isActive,
                  showInSearch: true,
                  isFiltered: false,
                  isLayerFiltered: false,
                  filterShow: false,
                })),
              })
            }
          })
        })
        .then(() => {
          if (first) {
            commit(
              'setEnabledUs',
              result.map(item => item.u)
            )
          }
          commit('setMapLayers', result)
        })
    },

    async getObjectData({ state, commit, dispatch }, params) {
      const { feature, layerId, featureId, u } = params
      const url = params.u || feature?.properties?.u
      const headersType = url.slice(1, url.slice(1).indexOf('/') + 1)

      dispatch('getHeadersLibrary', headersType)

      await axios
        .get(`${url}.json`, {
          params: {
            uid: state.token,
          },
        })
        .then(({ data }) => {
          commit('setSelectedLayer', data)
        })
    },

    async getInfoClickData({ getters, commit }, params) {
      const { bbox, x, y, pad } = params
      // const urls = getters.activeSourceUrls
      const layers = getters.activeMapLayers.filter(
        layer => !layer.item.source_protocol.includes('GeoJSON')
      )

      // const result = []
      layers.forEach(async layer => {
        commit('setInfoClickData', [])
        const url =
          layer.item.source_url.replace(/:item(s)?/, ':map').replace('.tile', '') + '.json'
        axios
          .get(url, {
            params,
          })
          .then(response => {
            if (response?.data) {
              // result.push(data.data)
              commit('addInfoClickData', {
                title: layer.item.title,
                ...response.data,
              })
            }
          })
          .catch(() => {})
      })
    },

    async getHeadersLibrary({ commit }, type) {
      await axios.get(`/stylist/-locale/ru/-apps/${type}.json`).then(({ data }) => {
        commit('setHeadersLibrary', data.messages)
      })
    },
  },
}
