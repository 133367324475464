<template>
  <header class="header">
    <div class="container">
      <div class="header__wrapper">
        <div
          class="header__start"
          v-if="!search"
        >
          <a
            href="#"
            @click.prevent="$router.push({ name: 'Index' })"
          >
            <svg-icon
              class="header__logo"
              name="logo"
            />
          </a>
        </div>
        <div class="header__logo-title ml-4">
          {{ headerText }}
        </div>

        <div class="header__end">
          <div
            v-if="false"
            class="header__control header__control--search-input"
            :class="{ active: search }"
          >
            <input
              ref="input"
              type="text"
              class="header__search-input"
              @blur="search = false"
            />
          </div>
          <button
            v-if="false && !search"
            class="header__control header__control--search"
            @click="search = true"
          >
            <span class="header__search-text">Найти </span>
            <svg-icon
              class="header__search-icon"
              name="search-icon"
            />
          </button>

          <button
            v-if="false"
            class="header__control header__control--eye"
          >
            <svg-icon name="eye" />
          </button>
          
          <button
            v-if="false"
            class="header__control header__control--language"
          >
            Eng
          </button>
        <a class="header__control header__control--lk"  href="https://rgis.krasnodar.ru/" > Личный кабинет</a>
        <!--<button 
          class="header__control header__control--lk"
          @click="navigate"
          role="link"
          >Личный кабинет</button> -->
        
          <button
            class="header__control header__control--notifications"
            v-if="false"
          >
            <svg-icon name="notifications" />
          </button>

          <button
            v-if="false"
            class="header__control header__control--rss"
          >
            <svg-icon name="rss-feed" />
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'DHeader',

  data: () => ({
    search: false,
  }),

  props: {
    headerText: {
      type: String,
      default: 'Региональная геоинформационная система',
    },
  },

  watch: {
    search(value) {
      if (value) {
        this.$refs.input.focus()
      }
    },
  },
}
</script>

<style lang="scss">
.header {
  $self: &;

  width: 100vw;
  height: 56px;
  padding: 8px 0;
  background-color: $header-bg;
  font-size: 14px;
  line-height: 18px;
  color: $white;

  @mixin controlAfter() {
    content: '';
    display: block;
    position: absolute;
    top: -8px;
    left: calc(100% + 34px);
    width: 2px;
    height: calc(100% + (8px * 2));
    background-color: rgba($white, 0.1);
    pointer-events: none;
  }

  > .container {
    height: 100%;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__end {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    height: 100%;
  }

  #{$self}__logo {
    width: 36px;
    height: 40px;
    transition: all 0.25s ease;

    &:hover {
      color: $white;
    }

    &-title {
      font-weight: 500;
    }
  }

  &__control {
    display: inline-flex;
    align-items: center;
    border: none;
    font-size: 14px;
    color: #dde1e6;
    box-shadow: none;
    outline: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    flex-shrink: 0;
    flex-grow: 0;

    .svg-icon {
      color: $white;
    }

    &:not(:last-child) {
      margin-right: 34px;
    }

    &:hover {
      * {
        color: $white;
      }
    }

    &--search {
      position: relative;
      margin-right: 70px !important;
      height: 100%;

      @include mobile {
        margin-right: 0 !important;
      }

      &::after {
        @include controlAfter;

        @include mobile {
          display: none;
        }
      }
    }

    &--search-input {
      position: relative;
      margin-right: 40px;
      padding-right: 0;
      margin-top: -8px;
      margin-bottom: -8px;
      height: calc(100% + 16px);
      width: 0;
      background-color: #002150;
      overflow: hidden;
      transition: all 0.25s ease;

      &.active {
        width: 100%;

        @include mobile {
          width: calc(100% + 30px);
          margin-left: -15px;
          margin-right: -15px !important;
        }
      }

      &:after {
        @include controlAfter;
        left: 100%;
      }
    }

    &--eye {
      @include mobile {
        display: none;
      }

      .svg-icon {
        width: 20px;
        height: 20px;
      }
    }

    &--notifications {
      @include mobile {
        display: none;
      }

      .svg-icon {
        width: 17px;
        height: 20px;
      }
    }

    &--rss {
      @include mobile {
        display: none;
      }

      .svg-icon {
        width: 18px;
        height: 18px;
      }
    }

    &--language {
      @include mobile {
        display: none;
      }
    }

    &--lk {
      @include mobile {
        display: none;
      }
    }
  }

  &__search-text {
    font-size: 14px;
    line-height: 18px;
    color: #dde1e6;
    margin-right: 16px;
  }

  &__search-input {
    padding-right: 70px;
    width: 100%;
    font-size: 14px;
    line-height: 1;
    text-align: right;
    border: none;
    background-color: #002150;
    box-shadow: none;
    color: $white;
    background-image: url('/images/search-icon.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 34px) center;

    &:focus,
    &:focus-visible {
      box-shadow: none;
      border: none;
      outline: none;
    }
  }

  .container {
    @include tablet {
      padding: 0 15px;
    }
  }
}
</style>
