var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-button',_vm._b({staticClass:"m-button",class:{
    'not-active': _vm.toggle && !_vm.active,
    active: _vm.active,
    'with-icon': _vm.icon,
    'is-fluid': _vm.fluid,
    'align-center': _vm.alignCenter,
    'm-button--icon': _vm.icon,
    [`m-button--type-${_vm.type}`]: _vm.type,
  },attrs:{"title":_vm.title},on:{"click":function($event){return _vm.$emit('click')}}},'b-button',_vm.$attrs,false),[(_vm.icon)?_c('svg-icon',{attrs:{"name":_vm.icon}}):_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }