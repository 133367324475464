<template>
  <b-dropdown
    class="m-dropdown"
    v-bind="$attrs"
  >
    <template v-slot:trigger="slotData">
      <slot
        name="trigger"
        v-bind="slotData"
      />
    </template>

    <slot />
  </b-dropdown>
</template>

<script>
export default {
  name: 'MDropdown',

  data: () => ({}),
}
</script>

<style lang="scss">
.m-dropdown {
  &.is-active {
    .dropdown-trigger {
      .button {
        .icon {
          color: $active-color;
        }
      }
    }
  }

  .dropdown-content {
    padding: 8px;
  }
  .dropdown-item {
    font-size: 12px;
  }
}
</style>
