import Vue from 'vue'
import axios from 'axios'
import store from '@/store'

export default function () {
  if (process.env?.NODE_ENV === 'development') {
    axios.defaults.baseURL = 'http://localhost'
  }

  axios.interceptors.request.use(
    config => {
      store.commit('common/setLoading', true)
      return config
    },
    error => {
      store.commit('common/setLoading', false)
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    config => {
      store.commit('common/setLoading', false)
      return config
    },
    error => {
      store.commit('common/setLoading', false)
      return Promise.reject(error)
    }
  )

  Vue.prototype.axios = axios
}
