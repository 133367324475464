<template>
  <div class="m-select-filters">
    <m-select-filter
      v-for="(item, index) in items"
      :key="index"
      :title="item.title"
      :value="openAll"
      :item="item"
      :openAll="openAll"
    />
  </div>
</template>

<script>
import MSelectFilter from './MSelectFilter.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'MSelectFilters',

  props: {
    openAll: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('layers', {
      items: 'groupedActiveMapLayers',
    }),
  },

  methods: {
    // groupSetActive() {
    //   const state = this.isAllItemsActive
    //   this.items.forEach(item => {
    //     item.active = !state
    //   })
    // },
  },

  components: {
    MSelectFilter,
  },
}
</script>
