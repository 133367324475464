<template>
  <div
    class="m-main-table"
    :class="{
      'active-half': active && !full,
      'active-full': active && full,
    }"
  >
    <div class="m-main-table__row m-main-table__top-row">
      <m-input
        ref="searchTagInput"
        placeholder="Поиск"
        icon-right="magnify"
        v-model="searchString"
        class="m-main-table__taginput mb-0"
        bg
      />
      <div class="m-main-table__row m-main-table__controls">
        <m-button
          class="mr-3"
          icon="printer"
        />
        <m-button
          class="mr-3"
          icon="file-export"
        />
        <m-button
          @click="setFullScreen"
          class="mr-3"
          :icon="full ? 'minimize' : 'maximize'"
        />
        <m-button
          @click="$emit('close')"
          icon="cross"
        />
      </div>
    </div>

    <b-table
      :data="searchString.length ? searchVariants : filteredItems"
      sticky-header
      :height="tableHeight"
      :pagination-position="paginationPosition"
      :paginated="!!filteredItems.length"
      :pagination-simple="false"
      :debounce-page-input="200"
      :pagination-range-before="5"
      pagination-order="is-centered"
      pagination-size="is-small"
      :range-after="3"
      :per-page="full ? 20 : 8"
      @click="setActiveMarker($event)"
    >
      <b-table-column
        label="ID"
        width="300"
        v-slot="props"
      >
        <span :style="{ fontSize: '12px' }">{{ props.row.id }}</span>
      </b-table-column>

      <b-table-column
        label="Название"
        v-slot="props"
      >
        {{ props.row.title }}
      </b-table-column>

      <b-table-column
        label="Слой"
        v-slot="props"
      >
        {{ props.row.layer }}
      </b-table-column>

      <b-table-column
        label="Координаты"
        v-slot="props"
        width="220"
      >
        <template v-if="props.row.geometry">
          <span :style="{ fontSize: '10px' }">{{ props.row.geometry.coordinates.join(', ') }}</span>
        </template>
        <template v-else>Отсутствуют</template>
      </b-table-column>

      <template #empty>
        <p>Ничего не найдено</p>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'MMainTable',

  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    full: false,
    paginationPosition: 'bottom',
    currentPage: 1,
    perPage: 20,
  }),

  computed: {
    ...mapGetters('search', ['searchVariants']),

    items() {
      return this.$store.getters['layers/allActiveFeatures']
    },
    filteredItems() {
      return this.items.filter(
        item => !item.isLayerFiltered || (item.isLayerFiltered && item.filterShow)
      )
    },
    tableHeight() {
      return this.full ? '85vh' : '35vh'
    },
    searchString: {
      get() {
        return this.$store.state.search.searchString
      },
      set(value) {
        this.$store.commit('search/setSearchString', value)
      },
    },
  },

  methods: {
    setFullScreen() {
      this.full = !this.full
    },

    setActiveMarker(feature) {
      this.$root.$emit('open-detail', {
        ...feature,
        id: feature.id,
        lng: feature.geometry?.coordinates?.[0],
        lat: feature.geometry?.coordinates?.[1],
        feature: feature,
        featureId: feature.id,
        layerId: feature.layerId,
      })
      this.$emit('close')
    },
  },

  watch: {
    active(value) {
      const method = value ? 'add' : 'remove'
      document.body.classList[method]('table-opened')
      this.full && document.body.classList[method]('table-opened--full')
    },
    full(value) {
      document.body.classList[value ? 'add' : 'remove']('table-opened--full')
    },
  },
}
</script>

<style lang="scss">
.m-main-table {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  border: 1px solid #dddee2;
  background-color: $white;
  z-index: $table-panel-index;
  transition: all 0.25s ease-in-out;

  @include mobile {
    overflow: auto;
  }

  &.active {
    &-half {
      padding: 24px;
      height: 50vh;
    }

    &-full {
      padding: 24px;
      height: 100vh;
    }
  }

  &__row {
    display: flex;
    align-items: center;

    @include mobile {
      flex-wrap: wrap;
    }
  }

  &__top-row {
    width: 100%;
    margin-bottom: 10px;
  }

  &__taginput {
    width: 100%;
    margin-right: 40px;

    @include mobile {
      margin-right: 0;
    }
  }

  &__controls {
    @include mobile {
      width: 100%;
      justify-content: space-between;
      margin-left: auto;
      margin-top: 10px;
    }
  }

  .table {
    tbody {
      tr {
        cursor: pointer;

        &:hover {
          background-color: rgba($active-color, 0.125);
        }

        td {
          font-size: 14px;
        }
      }
    }
  }

  .top.level {
    .level-right {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
