<template>
  <div
    class="m-layers-section"
    v-if="showInSearch"
  >
    <m-collapse
      :title="title"
      background
      :value="openAll"
    >
      <template #title-prepend>
        <m-checkbox
          class="m-collapse__group-toggle mr-0"
          :value="isAllItemsActive"
          @input="groupSetActive($event)"
          :indeterminate="isGroupIndeterminate"
        />
      </template>

      <div class="card-content">
        <m-separator class="mb-3" />
        <div
          v-for="(item, index) in items"
          :key="`second-${item.u}`"
          class="d-flex mb-2"
        >
          <template v-if="item.showInSearch">
            <template v-if="item.iconClass">
              <div class="m-layers-section__row">
                <m-checkbox
                  class="m-layers-section__third-checkbox mb-1 ml-3"
                  v-model="item.active"
                >
                  {{ item.title }}
                </m-checkbox>
                <img
                  class="m-layers-section__layer-image"
                  :src="`https://pprgis.krasnodar.ru${item.iconClass.replace(
                    /^feature\:\/\//,
                    ''
                  )}`"
                  alt=""
                />
              </div>
            </template>
            <m-checkbox
              v-else
              v-model="item.active"
              class="m-layers-section__third-checkbox mb-1 ml-3"
            >
              {{ item.title }}
            </m-checkbox>
          </template>
        </div>
      </div>
    </m-collapse>
  </div>
</template>

<script>
import MSeparator from './MSeparator.vue'
import MCollapse from './MCollapse.vue'
import MCheckbox from './MCheckbox.vue'

export default {
  name: 'MSelectLayerSection',

  props: {
    item: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    openAll: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isCollapseOpened: false,
  }),

  computed: {
    items() {
      return this.item.childs
    },

    showInSearch() {
      return this.items.find(item => item.showInSearch)
    },

    isAllItemsActive() {
      return this.items.every(item => item.active)
    },

    isGroupIndeterminate() {
      return !!(this.items.find(item => item.active) && !this.items.every(item => item.active))
    },
  },

  methods: {
    groupSetActive() {
      const state = this.isAllItemsActive
      this.items.forEach(item => {
        item.active = !state
      })
    },
  },

  components: {
    MSeparator,
    MCollapse,
    MCheckbox,
  },
}
</script>

<style lang="scss">
.m-layers-section {
  $self: &;

  &__third-checkbox {
    font-size: 12px;
    color: $black;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__layer-image {
    max-width: 18px;
  }
}
</style>
