<template>
  <div id="app">
    <b-loading
      v-if="!isIndexPage"
      is-full-page
      v-model="loading"
    />
    <d-header v-if="$route.name !== 'Map'" />

    <d-subheader v-if="$route.name !== 'Map'" />

    <router-view />

    <d-footer v-if="$route.name !== 'Map'" />
  </div>
</template>

<script>
import { widgetId } from '@/data/widget'
import { mapState } from 'vuex'
import DHeader from '@/components/DHeader.vue'
import DSubheader from '@/components/DSubheader.vue'
import DFooter from '@/components/DFooter.vue'
import axios from 'axios'

export default {
  name: 'App',

  data: () => ({
    widgetId,
  }),

  computed: {
    ...mapState('common', ['loading', 'documentTitle']),

    isIndexPage() {
      return this.$route.name === 'Index'
    },
  },

  watch: {
    documentTitle(value) {
      document.title = value
    },
  },

  components: {
    DHeader,
    DSubheader,
    DFooter,
  },
}
</script>
