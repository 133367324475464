<template>
  <div class="m-filter modal-card">
    <div class="m-filter__inner">
      <div class="m-filter__tools mb-4">
        <div
          class="m-filter__settings"
          @click="$emit('open-settings')"
        >
          Фильтры
          <svg-icon
            name="settings"
            class="ml-2"
          />
        </div>

        <div class="m-filter__row">
          <div
            class="m-filter__reset mr-5"
            @click="clearFilter"
          >
            Сбросить настройки
          </div>

          <svg-icon
            name="cross"
            class="cursor-pointer"
            @click.native="close"
          />
        </div>
      </div>

      <div class="mb-4">
        <m-select
          v-model="model.layer"
          :options="layersOptions"
          label="Слои"
        />
      </div>

      <template v-for="(group, index) in groupedActiveMapLayers">
        <div
          class="mb-3"
          :key="group.u"
          v-if="group.activeFilter"
        >
          <h3 class="has-text-weight-bold is-size-6 mt-5">
            {{ group.title }}
          </h3>
          <template v-for="(layer, index) in group.items">
            <div
              :key="`layer-${index}`"
              class="mb-4"
              v-if="layer.activeFilter"
            >
              <h4
                v-if="layer.filters.some(el => el.activeFilter)"
                class="mt-3 mb-2 is-block is-size-6"
              >
                {{ layer.item.title }}
              </h4>
              <template v-for="filter in layer.filters">
                <m-input
                  :key="`${filter.name}-${index}`"
                  v-if="filter.activeFilter"
                  :label="filter.config.title"
                  placeholder="Введите значение"
                  bg
                  icon-right
                  v-model="filter.value"
                  @input="filterInputHandler(filter, layer, $event)"
                  @blur="filterBlurHandler(layer)"
                />
              </template>
            </div>

            <m-separator
              v-if="layer.activeFilter"
              class="mb-2"
            />
          </template>
        </div>
      </template>
    </div>

    <m-button
      class="m-filter__close-btn"
      type="primary"
      @click.native="applyFilter"
    >
      Применить
    </m-button>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import MSeparator from '@/components/MSeparator.vue'

export default {
  name: 'MFilter',

  props: {
    dropdown: {
      default: () => null,
    },
  },

  data: () => ({
    state: false,
    model: {
      layer: 'all-layers',
    },
  }),

  computed: {
    ...mapState('layers', ['mapLayers']),
    ...mapGetters('layers', ['groupedActiveMapLayers']),

    layersOptions() {
      return [
        {
          text: 'Все',
          id: 'all-layers',
        },
        ...this.mapLayers?.map(item => ({
          ...item,
          text: item.item?.title,
          id: item.item?.['mapware.layers'],
        })),
      ]
    },

    filtersLength() {
      return this.groupedActiveMapLayers.reduce((acc, layer) => {
        acc =
          acc +
          layer.items.reduce(
            (itemsAcc, item) =>
              itemsAcc + item.filters.filter(filter => filter.activeFilter)?.length,
            0
          )
        return acc
      }, 0)
    },
  },

  methods: {
    close() {
      this.dropdown.toggle()
    },

    toggle() {
      this.dropdown.toggle()
      this.$emit(this.state ? 'opened' : 'closed')
    },

    clearFilter() {
      Object.keys(this.model).forEach(key => {
        this.model[key] = null
      })
    },

    applyFilter() {
      this.close()
      this.$emit('close')
      this.$emit('apply')
    },

    filterInputHandler(filterItem, layer, value) {
      if (value) {
        if (!layer.filtered) {
          layer.filtered = {}
        }
        layer.filtered = layer.filters
          .filter(filter => filter.value)
          .reduce((acc, filter) => {
            acc[filter.name] = filter.value
            return acc
          }, {})
      } else {
        if (!layer.filtered || Object.keys(layer.filtered).length <= 1) {
          layer.filtered = null
        } else {
          delete layer.filtered[filterItem.name]
        }
      }
    },

    filterBlurHandler(layer) {
      const filteredArr = Object.entries(layer?.filtered || {})
      if (filteredArr?.length) {
        layer.isFiltered = true
        if (layer.features) {
          layer.features.forEach(feature => {
            feature.isLayerFiltered = true
            const { properties } = feature
            const matched = filteredArr.reduce((acc, [key, value]) => {
              if (properties?.[key]?.toLowerCase().includes(value.toLowerCase())) {
                acc++
              }
              return acc
            }, 0)
            feature.filterShow = matched === filteredArr.length
          })
        }
      } else {
        layer.isFiltered = false
        if (layer.features) {
          layer.features.forEach(feature => {
            feature.isLayerFiltered = false
            feature.filterShow = true
          })
        }
      }
    },
  },

  watch: {
    dropdown: {
      deep: true,
      handler(value) {
        if (value) {
          this.dropdown?.$on('active-change', state => {
            this.state = state
          })
        }
      },
    },

    // filtersLength() {
    //   this.groupedActiveMapLayers.forEach(layer => {
    //     layer.items.forEach(item => {
    //       item.filters.forEach(filter => (filter.value = ''))
    //     })
    //   })
    // },
  },

  components: {
    MSeparator,
  },
}
</script>

<style lang="scss">
.m-filter {
  $self: &;

  overflow: auto;
  padding: 20px;
  z-index: 1;
  background-color: $white;
  max-width: 100%;

  &__inner {
    min-height: 355px;
    max-height: 355px;
    overflow: auto;
    margin-bottom: 16px;
  }

  &__row {
    display: flex;
    align-items: center;
  }

  #{$self}__tools {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #{$self}__settings {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: $black;
    cursor: pointer;
    user-select: none;

    * {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  #{$self}__reset {
    cursor: pointer;
    user-select: none;
  }
}
</style>
