<template>
  <m-field :label="label">
    <component
      :is="autocomplete ? 'b-autocomplete' : 'b-input'"
      class="m-input"
      :class="{
        bg,
      }"
      :placeholder="placeholder"
      :icon-right="iconRight"
      v-model="$value"
      :data="autocomplete"
      open-on-focus
      @blur="$emit('blur')"
    />
  </m-field>
</template>

<script>
export default {
  name: 'MInput',

  props: {
    value: {
      type: [String, Number],
    },
    bg: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Поиск по словам',
    },
    iconRight: {
      type: String,
      default: 'magnify',
    },
    autocomplete: {
      type: Array,
    },
  },

  computed: {
    $value: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style lang="scss">
.m-input {
  &.control {
    &.bg {
      .input {
        background-color: $bg;
      }
    }
    .input {
      height: 48px;
      box-shadow: unset;
      border: 1px solid $border-color;
      border-radius: 8px;
    }

    &.has-icons-right {
      .input {
        box-shadow: unset;

        &::placeholder {
          font-size: 16px;
          line-height: 19px;
          font-weight: 500;
          color: #828282;
        }

        ~ .icon {
          width: 20px;
          height: 20px;
          top: calc(50% - 10px);
          right: 14px;
          color: $button-text;
        }

        &:focus {
          ~ .icon {
            color: $button-text;
          }
        }
      }
    }
  }

  &.autocomplete {
    .dropdown-content {
      width: 96%;
      transform: translateX(2%);
    }
  }
}
</style>
