<template>
  <div class="m-search-result-item">
    <div class="m-search-result-item__wrapper">
      <div
        class="m-search-result-item__image-wrapper"
        v-if="item.icon"
      >
        <img
          class="m-search-result-item__image"
          :src="item.icon"
        />
      </div>
      <div
        v-else
        class="m-search-result-item__image-wrapper m-search-result-item__image-wrapper--empty"
      />
      <!-- <div class="m-search-result-item__image">
      </div> -->
      <div class="m-search-result-item__info">
        <div class="m-search-result-item__title">{{ item.title }}</div>
        <p class="m-search-result-item__description">
          В данный момент ни в одной точке нет какого-либо описания. Возможно, стоит его добавить,
          или избавиться совсем от этого текста
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MSearchResultItem',

  props: {
    item: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.m-search-result-item {
  $self: &;

  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;
  }

  &__image-wrapper {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    flex-grow: 0;
    // background-color: lighten(#0c6130, 10%);
    border-radius: 50%;
    margin-right: 8px;

    &--empty {
      background-color: unset;
    }
  }

  &__image {
    flex-shrink: 0;
    flex-grow: 0;
    width: 60%;
  }

  &__title {
    font-size: 16px;
    line-height: 19px;
    color: $black;
    font-weight: 500;
    margin-bottom: 4px;
  }

  &__description {
    font-size: 14px;
    line-height: 16px;
    color: #5b5b5b;
  }
}
</style>
