<template>
  <div
    class="m-layers-section m-select-filter"
    v-if="showInSearch"
  >
    <m-collapse
      :title="title"
      background
      :value="openAll"
      class="mb-3"
    >
      <template #title-prepend>
        <m-checkbox
          class="m-collapse__group-toggle mr-0"
          :value="item.items.every(el => el.activeFilter)"
          @input="groupSetActive($event)"
          :indeterminate="
            !item.items.every(el => el.activeFilter) && item.items.some(el => el.activeFilter)
          "
        />
      </template>

      <div class="card-content">
        <m-separator class="mb-3" />
        <div
          v-for="(subItem, index) in item.items.filter(e => e.showInSearch)"
          :key="`second-${subItem.u}`"
          class="d-flex mb-2"
        >
          <template v-if="subItem && subItem.item && item.showInSearch">
            <m-collapse
              background
              :value="openAll"
              :title="subItem.item.title"
              no-mb
            >
              <template #title-prepend>
                <m-checkbox
                  :style="{ alignItems: 'flex-start' }"
                  class="m-select-filter__group-checkbox pt-3 mr-0 align-items-center"
                  :value="subItem.filters.every(filter => filter.activeFilter)"
                  :indeterminate="
                    !subItem.filters.every(filter => filter.activeFilter) &&
                    subItem.filters.some(filter => filter.activeFilter)
                  "
                  @input="changeSubitemFilterActivity(subItem, $event, item)"
                />
              </template>
              <div class="card-content">
                <m-checkbox
                  v-for="(filter, index) in subItem.filters"
                  :key="`filter-${index}`"
                  class="m-select-filter__filter-checkbox w-100 is-flex"
                  v-model="filter.activeFilter"
                  @input="changeItemFilterActivity(filter, subItem, item, $event)"
                >
                  <small>{{ filter.config?.title }}</small>
                </m-checkbox>
              </div>
            </m-collapse>
          </template>
        </div>
      </div>
    </m-collapse>
  </div>
</template>

<script>
import MSeparator from './MSeparator.vue'
import MCollapse from './MCollapse.vue'
import MCheckbox from './MCheckbox.vue'
import { mapState } from 'vuex'

export default {
  name: 'MSelectFilter',

  props: {
    item: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    openAll: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    isCollapseOpened: false,
  }),

  computed: {
    ...mapState('layers', {
      items: 'groupedMapLayers',
    }),

    showInSearch() {
      return this.item.items.some(item => item.showInSearch)
    },

    isAllItemsActive() {
      return this.item.items.every(item => item.activeFilter)
    },

    isSomeItemsActive() {
      return this.item.items.some(item => item.activeFilter)
    },

    isGroupIndeterminate() {
      return !!(
        this.item.items.find(item => item.activeFilter) &&
        !this.item.items.every(item => item.activeFilter)
      )
    },
  },

  methods: {
    groupSetActive(state) {
      this.item.activeFilter = state
      this.item.items.forEach(item => {
        item.activeFilter = state
        item.filters.forEach(filter => {
          filter.activeFilter = state
        })
      })
    },

    changeItemFilterActivity(filter, subItem, item, state) {
      filter.activeFilter = state
      if (filter.value) {
        filter.value = ''
      }
      subItem.activeFilter = subItem.filters.some(filter => filter.activeFilter)
      item.activeFilter = item.items.some(e => e.activeFilter)
    },

    changeSubitemFilterActivity(subItem, state, item) {
      subItem.activeFilter = state
      subItem.filters.forEach(filter => {
        filter.activeFilter = state
        if (filter.value) {
          filter.value = ''
        }
      })
      item.activeFilter = item.items.some(e => e.activeFilter)
    },
  },

  mounted() {
    this.item.activeFilter = this.item.items.some(item => item.activeFilter)
  },

  watch: {
    // isSomeItemsActive(value) {
    //   this.item.activeFilter = value
    // },
  },

  components: {
    MSeparator,
    MCollapse,
    MCheckbox,
  },
}
</script>

<style lang="scss">
.m-select-filter {
  $self: &;

  &__group-checkbox {
    + .m-collapse__wrapper {
      .card-header-title {
        padding-left: 10px;
      }
    }
  }
}
</style>
