<template>
  <div
    class="map"
    id="map"
  >
    <div
      class="right-middle-buttons"
      v-if="!preview"
    >
      <div
        class="map__rmb"
        data-tool-zoom
      >
        <m-button
          @click="zoomIn"
          icon="plus"
        />
        <div class="map__button-separator"></div>
        <m-button
          @click="zoomOut"
          icon="minus"
        />
      </div>

      <m-button
        class="map__location-button"
        data-tool-location
        @click="locate"
        :loading="isLocating"
        icon="current-location"
      >
      </m-button>
    </div>

    <div
      class="right-top-buttons"
      v-if="!preview"
    >
      <m-button
        data-tool-layers
        class="mr-3"
        @click="$emit('open-layers')"
        icon="stack"
      />

      <m-dropdown
        v-if="false"
        class="mr-3"
      >
        <template #trigger="{ active }">
          <m-button icon="tool" />
        </template>

        <b-dropdown-item aria-role="listitem">Действие 1</b-dropdown-item>
        <b-dropdown-item aria-role="listitem">Действие 2</b-dropdown-item>
        <m-separator />
        <b-dropdown-item aria-role="listitem">Еще действие</b-dropdown-item>
      </m-dropdown>

      <m-button
        class="mr-3"
        toggle
        :active="isRulerActive"
        @click="setRuler"
        icon="ruler"
      />

      <m-button
        class="mr-3"
        icon="printer"
      />

      <m-button
        data-tool-share
        icon="file-export"
      />
    </div>

    <div
      v-if="!preview"
      class="table-button"
      data-tool-table
    >
      <m-button
        size="default"
        @click="$emit('open-table')"
        fluid
        align-center
      >
        <span class="color-black">Таблица</span>
        <svg-icon
          name="caret-up"
          class="ml-1"
        />
      </m-button>
    </div>

    <div
      v-if="!preview"
      class="home-button"
      data-tool-table
    >
      <m-button
        size="default"
        @click="$router.push({ name: 'Index' })"
        title="На главную"
        fluid
        align-center
      >
        <svg-icon
          name="logo"
          style="height: 36px; width: 36px"
        />
        <span
          class="color-black"
          style="margin-left: 5px"
          >{{ $attrs.title }}</span
        >
        <svg-icon
          name="caret-left"
          class="ml-1"
        />
      </m-button>
    </div>
    <div
      v-if="preview"
      class="preview-name"
      data-tool-table
    >
      <m-button
        size="default"
        fluid
        align-center
      >
        <svg-icon
          name="logo"
          style="height: 36px; width: 36px"
        />
        <span
          class="color-black"
          style="margin-left: 5px"
          >{{ $attrs.title }}</span
        >
      </m-button>
    </div>
    <l-map
      class="map__map-container"
      :style="preview ? 'height: 100%' : 'height: 100vh'"
      :center="center"
      :zoom="zoom"
      :max-bounds="bounds"
      :minZoom="6"
      ref="map"
      :options="mapOptions"
      @update:bounds="boundsUpdateHandler"
    >
      <l-tile-layer
        v-if="url"
        :url="url"
      />
      <l-ruler
        ref="ruler"
        :options="rulerOptions"
        v-model="isRulerActive"
      />

      <l-marker
        v-if="userLocation"
        :lat-lng="userLocation"
        :icon="userLocationIcon"
      />

      <template v-for="(layer, layerIndex) in filteredMapLayers">
        <l-marker-cluster
          :key="`cluster-${layerIndex}-${layer.u}`"
          v-if="layer.features"
          :options="clusterOptions"
        >
          <l-geo-json
            v-if="layer.features"
            :key="layer.u"
            :geojson="layer"
            :options="options"
            :options-style="styleFunction"
          />
          <!--
              <template v-for="(feature, featureIndex) in layer.features">
                <l-geo-json
                  :key="feature.id"
                  :geojson="feature"
                  @click="pointClickHandler(layer, feature)"
                />
              </template>
            <template v-for="(feature, featureIndex) in layer.features">
                <l-geo-json
                  :key="feature.id"
                  :geojson="feature"
                  @click="pointClickHandler(layer, feature)"
                />
              </template> 
          -->
        </l-marker-cluster>
        <l-wms-tile-layer
          v-else-if="layer.item.source_protocol === 'TileLayer'"
          :key="`tile-layer-${layerIndex}-${layer.u}`"
          :base-url="
            layer.item.source_url.includes('https://')
              ? `${layer.item.source_url.replace('.tile', '')}.tile`
              : `https://pprgis.krasnodar.ru${layer.item.source_url.replace('.tile', '')}.tile`
          "
          visible
          format="image/png"
          transparent
        />
        <l-wms-tile-layer
          :key="`wms-tile-layer-${layerIndex}-${layer.u}`"
          v-else-if="layer.item.source_protocol === 'WmsLayer'"
          :base-url="
            layer.item.source_url.includes('https://')
              ? layer.item.source_url
              : `https://pprgis.krasnodar.ru${layer.item.source_url}`
          "
          visible
        />
      </template>
    </l-map>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { LMap, LTileLayer, LMarker, LIcon, LControl, LGeoJson, LWMSTileLayer } from 'vue2-leaflet'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import LRuler from 'vue2-leaflet-ruler'
import MTileLayer from '@/components/MTileLayer.vue'

export default {
  name: 'Map',
  props: {
    title: {
      type: String,
      default: 'На главную',
    },

    preview: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    L: null,
    map: null,
    controls: true,
    // center: [45.046526, 38.978311],
    bbox: '',
    zoom: 15,
    isRulerActive: false,
    crs: 'EPSG3857',
    isLocating: false,
    userLocation: null,
    ruler: null,
    mapOptions: {
      scrollWheelZoom: false,
      smoothWheelZoom: true,
      smoothSensitivity: 2,
    },
    clusterOptions: {
      animate: false,
      animateAddingMarkers: false,
      removeOutsideVisibleBounds: true,
      showCoverageOnHover: false,
    },
    rulerOptions: {
      position: 'topright',
      circleMarker: {
        color: 'red',
        radius: 2,
      },
      lineStyle: {
        color: 'red',
        dashArray: '0.2',
      },
      lengthUnit: {
        display: 'км',
        decimal: 3,
        factor: null,
        label: 'Расстояние:',
      },
      angleUnit: {
        display: '&deg;',
        decimal: 2,
        factor: null,
        label: 'Угол:',
      },
    },
    infoClickPopup: null,
  }),

  computed: {
    ...mapState('common', ['url', 'center']),
    ...mapState('layers', ['mapLayers', 'infoClickData']),

    ...mapGetters('common', ['mapUrls', 'bounds']),
    ...mapGetters('layers', ['activeMapLayers', 'filteredMapLayers', 'activeSourceUrls']),

    userLocationIcon() {
      return this.L.icon({
        iconUrl: '/assets/leaflet/marker-human.svg',
        iconSize: [40, 40],
        iconAnchor: [20, 20],
      })
    },

    options() {
      return {
        pointToLayer: this.pointToLayer,
        onEachFeature: this.onEachFeatureFunction,
        filter(feature) {
          return !!(!feature.isFiltered || feature.filterShow)
        },
      }
    },

    popupContent() {
      if (!this.infoClickData?.length) return ''

      const wrapper = createElement('div', 'infoclick-wrapper')
      this.infoClickData.forEach(data => {
        if (data.items?.length) {
          const section = createElement('div', 'infoclick-section')
          let sectionTitle = data.title
          const title = createElement('div', 'infoclick-section-title', sectionTitle)
          section.appendChild(title)

          data.items.forEach(item => {
            const element = createElement('div', 'infoclick-section-item', item.title)
            element.addEventListener('click', () => {
              this.$store
                .dispatch('layers/getObjectData', {
                  u: item.u,
                })
                .then(() => {
                  this.$emit('show-detail')
                })
                .catch(() => {
                  this.$emit('show-detail', false)
                })
            })
            section.appendChild(element)
          })
          wrapper.appendChild(section)
        }
      })

      function createElement(tag, className, content) {
        const element = document.createElement(tag)
        element.classList.add(className)
        if (content) {
          element.innerText = content
        }
        return element
      }

      return wrapper
    },
  },

  created() {
    if (this.preview) {
      this.zoom = 11
    }
  },

  mounted() {
    this.L = window.L
    this.map = this.$refs.map.mapObject
    window.map = this.map

    this.map.on('locationfound', event => {
      this.userLocation = event.latlng
      this.isLocating = false
      this.map.flyTo(this.userLocation, 17)
    })

    this.$root.$on('open-detail', payload => {
      this.setActiveMarker(payload)
      this.$nextTick(() => {
        this.pointClickHandler(payload)
      })
    })

    // this.map.addEventListener('mousemove', e => {
    //   console.log(e)
    // })

    this.map.addEventListener('click', e => {
      if (this.infoClickPopup) {
        this.map.removeLayer(this.infoClickPopup)
      }

      const { lat, lng } = e.latlng
      this.infoClickPopup = new this.L.Popup({
        className: 'infoclick',
      })

      this.infoClickPopup.setLatLng(new this.L.LatLng(lat, lng))
      this.infoClickPopup.setContent(
        `<div class="infoclick__wrapper">
          <div class="infoclick-section">
            <div class="infoclick-section-title">Получаем данные</div>
            <div class="infoclick-section-item">Ожидайте, идет загрузка</div>
          </div>
        </div>`
      )
      this.map.addLayer(this.infoClickPopup)

      let pad = 0.0001 + (18 - e.target.getZoom()) * 0.00035

      this.getInfoClickData({
        bbox: this.bbox,
        x: lat,
        y: lng,
        pad: pad.toFixed(4),
      }).then(() => {
        setTimeout(() => {
          this.infoClickPopup.setContent(this.popupContent)
          this.map.addLayer(this.infoClickPopup)
        }, 500)
      })
    })
  },

  methods: {
    ...mapActions('layers', ['getInfoClickData']),

    random() {
      return Math.random().toString()
    },
    zoomIn() {
      this.map.zoomIn()
    },

    zoomOut() {
      this.map.zoomOut()
    },

    locate() {
      this.isLocating = true
      this.map.locate({
        // watch: true,
        enableHighAccuracy: true,
        setView: false,
      })
    },

    getCentroid(arr) {
      return arr.reduce(
        function (x, y) {
          return [x[0] + y[0] / arr.length, x[1] + y[1] / arr.length]
        },
        [0, 0]
      )
    },

    pointClickHandler({ feature, layerId, featureId }) {
      this.$store
        .dispatch('layers/getObjectData', {
          feature,
          layerId,
          featureId,
        })
        .then(() => {
          this.$emit('show-detail')
        })
        .catch(() => {
          this.$emit('show-detail', false)
        })
    },

    setRuler() {
      const ruler = document.querySelector('.leaflet-bar.leaflet-ruler.leaflet-control')
      const isRulerOn = ruler.classList.contains('leaflet-ruler-clicked')
      this.isRulerActive = !isRulerOn
      ruler.dispatchEvent(new Event('click'))
    },

    boundsUpdateHandler(event) {
      const c = this.map.getCenter()
      const center = [c.lng, c.lat]
      this.bbox = event.toBBoxString()
      // this.$store.commit('setCenter', center)
    },

    setActiveMarker(params) {
      const { lat, lng, feature } = params
      if (lat && lng) {
        try {
          this.map.setView({ lat, lng }, 17)
        } catch (error) {}
      }

      this.$nextTick(() => {
        const element = document.querySelector(
          `.map__marker.map__marker--${params?.feature?.id || params.id}`
        )
        document
          .querySelectorAll('.map__marker.map__marker--active')
          .forEach(marker => marker.classList.remove('map__marker--active'))

        if (element) {
          element.classList.add('map__marker--active')
        }
      })
    },

    onEachFeatureFunction(feature, layer) {
      layer.addEventListener('click', () => {
        const { id, layerId, properties } = feature
        const u = properties.u

        this.setActiveMarker({
          id,
          feature,
        })
        this.pointClickHandler({
          feature,
          featureId: id,
          layerId,
        })
      })

      layer.bindTooltip('<div>' + feature.properties.title + '</div>', {
        permanent: false,
        sticky: true,
      })
    },

    pointToLayer(feature, latlng) {
      const iconSize = feature.active ? [32, 32] : [48, 48]

      // if (feature.id === '4c5c4869-4c7a-4b8a-a10a-9b4397b55093') {
      //   console.log(feature)
      // }

      return this.L.marker(latlng, {
        icon: this.L.icon({
          iconUrl:
            feature.iconClass === 'maps/map-marker'
              ? '/images/default-marker.svg'
              : `https://pprgis.krasnodar.ru${
                  feature.iconClass[0] === '/' ? '' : '/'
                }${feature.iconClass.replace(/^feature\:\/\//, '')}`,
          iconSize,
          iconAnchor: [32, 32],
          className: `filtered-${feature.filterShow} map__marker map__marker--${
            feature.id || feature.properties?.['dinv.objects2']
          }`,
        }),
      })
    },

    styleFunction() {
      const fillColor = '#e4ce7f' // important! need touch fillColor in computed for re-calculate when change fillColor

      return () => ({
        weight: 2,
        width: 50,
        height: 50,
        color: '#0000ff',
        opacity: 1,
        fillColor: '#0000ff',
        fillOpacity: 1,
        backgroundColor: '#ff0000',
        borderRadius: '50%',
        display: 'block',
      })
    },
  },

  // watch: {
  //   filteredMapLayers: {
  //     deep: true,
  //     handler(value) {
  //       this.map.eachLayer(layer => {
  //         const { feature } = layer
  //         if (feature) {
  //           this.$nextTick(() => {
  //             console.log(123)
  //             layer.removeFrom(map)
  //             if (!feature.isFiltered || feature.filterShow) {
  //               layer.addTo(map)
  //             }
  //           })
  //         }
  //         // layer.addTo(map)
  //       })
  //     },
  //   },
  // },

  props: {
    preview: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    LMap,
    LTileLayer,
    MTileLayer,
    LIcon,
    LMarker,
    LControl,
    LGeoJson,
    LRuler,
    'l-wms-tile-layer': LWMSTileLayer,
    'l-marker-cluster': Vue2LeafletMarkerCluster,
  },
}
</script>

<style lang="scss">
.map {
  position: relative;
  height: 100%;
  &__rmb {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $white;
    border: 1px solid $border-color;
    border-radius: 8px;
    margin-bottom: 8px;

    .button {
      max-width: 100%;
      border: none;
    }
  }

  &__button-separator {
    width: 28px;
    height: 1px;
    background-color: $border-color;
  }

  &__location-button {
    width: 100%;
  }

  &__marker {
    &.map__marker--active {
      width: 55px !important;
      height: 55px !important;
    }
  }

  .right-middle-buttons {
    width: 44px;
    position: absolute;
    top: 50%;
    right: 24px;
    transform: translateY(-50%);
    z-index: $controls-layer-index;
  }

  .search-panel {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 440px;
    max-width: calc(100vw - 40px);
    z-index: $controls-layer-index;
  }

  .table-button {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: $controls-layer-index;

    @include mobile {
      left: unset;
      right: 10px;
      transform: unset;
    }

    .button {
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
    }
  }

  .home-button {
    position: absolute;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    z-index: $controls-layer-index;

    @include mobile {
      top: unset;
      bottom: 0;
      left: 10px;
      transform: unset;
    }

    .button {
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
    }
  }
  .preview-name {
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    z-index: $controls-layer-index;

    @include mobile {
      top: unset;
      bottom: 0;
      left: 10px;
      transform: unset;
    }

    .button {
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
    }
  }
  .right-top-buttons {
    position: absolute;
    top: 24px;
    right: 24px;
    display: flex;
    z-index: $controls-layer-index;

    @include mobile {
      display: none;
    }
  }

  .leaflet-bar.leaflet-ruler.leaflet-control {
    display: none;
  }

  &__logo {
    position: fixed;
    top: 15px;
    left: 450px;

    z-index: 999;

    .svg-icon {
      width: 50px;
      height: 50px;
    }
  }

  &__map-container {
    @include mobile {
      height: 100vh !important;
    }
  }
}

.infoclick {
  width: 250px;

  &-section {
    &:first-child {
      .infoclick-section-title {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }

    &:last-child {
      .infoclick-section-item {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    &-title {
      padding: 3px 24px;
      background-color: $bg;
      font-weight: 600;
      color: #777;
    }

    &-item {
      padding: 10px 24px;
      background-color: $white;

      &:hover {
        background-color: #f9f9fb;
        cursor: pointer;
      }
    }
  }

  .leaflet-popup-content-wrapper {
    padding: 0;
    border-radius: 0;
    background: none;
    border-radius: 5px;
  }

  .leaflet-popup-content {
    width: 100% !important;
    margin: 0;
  }
}
</style>
