import MButton from '@/components/MButton'
import MDropdown from '@/components/MDropdown'
import MSeparator from '@/components/MSeparator'
import MInput from '@/components/MInput'
import MSelect from '@/components/MSelect'
import MField from '@/components/MField'
import MTaginput from '@/components/MTaginput'
import SvgIcon from '@/components/SvgIcon'
import RGIS from '@/views/RGIS.vue'

export default function (Vue) {
  Vue.component('m-button', MButton)
  Vue.component('m-dropdown', MDropdown)
  Vue.component('m-separator', MSeparator)
  Vue.component('m-input', MInput)
  Vue.component('m-select', MSelect)
  Vue.component('m-field', MField)
  Vue.component('svg-icon', SvgIcon)
  Vue.component('m-taginput', MTaginput)

  Vue.component('m-rgis', RGIS)
}
