<template>
  <div class="m-item-detail">
    <slot name="top" />
    <template v-if="error">
      <svg-icon
        name="arrow-left"
        class="m-item-detail__back-trigger mr-2"
        @click.native="goBack"
      />
      <p>{{ error }}</p>
    </template>
    <template v-else="">
      <div class="m-item-detail__row mb-5">
        <svg-icon
          name="arrow-left"
          class="m-item-detail__back-trigger mr-2"
          @click.native="goBack"
        />
        <h3
          v-if="selectedLayer.item"
          class="has-text-weight-bold is-size-5"
        >
          {{ selectedLayer.item.title || selectedLayer.item['Наименование'] }}
        </h3>
      </div>
      <div
        v-for="layer in dataToShow"
        class="mb-4"
      >
        <p class="has-text-weight-bold">{{ headersLibrary[layer.title] || layer.title }}</p>
        <!-- <p>{{ layer.text }}</p> -->
        <div v-html="layer.text"></div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'MItemDetail',

  props: {
    selectedLayer: {
      type: Object,
      required: true,
    },
    selectedLayerData: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapState('layers', ['headersLibrary']),

    dataToShow() {
      const exclude = ['title', 'bbox']
      return this.selectedLayerData.filter(item => !exclude.includes(item.title))
    },
  },

  methods: {
    goBack() {
      this.$emit('go-back')
    },
  },
}
</script>

<style lang="scss">
.m-item-detail {
  &__row {
    display: flex;
    align-items: center;
  }

  &__back-trigger {
    cursor: pointer;
    user-select: none;
  }
}
</style>
