<template>
  <div class="subheader">
    <div class="container">
      <div class="subheader__wrapper">
        <div class="subheader__start">
          <div>
            <h1 class="subheader__title">
              Публичный портал<br />Региональной геоинформационной системы
            </h1>
            <h4 class="subheader__subtitle">Краснодарского края</h4>
          </div>
        </div>
        <div class="subheader__end">
          <ul class="subheader__menu">
            <li v-for="link in links">
              <a
                :href="link.link"
                target="_blank"
                >{{ link.name }}</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DSubheader',

  computed: {
    links() {
      return this.$store.state.main.topLinks
    },
  },
}
</script>

<style lang="scss">
.subheader {
  background-image: url(/images/subheader-pattern.svg);
  background-repeat: no-repeat;
  background-position: 100% 0;
  border-bottom: 2px solid #f3f3f3;

  &__wrapper {
    padding: 90px 0;
    display: flex;
    justify-content: space-between;

    @include tablet {
      padding: 64px 0;
    }

    @include mobile {
      flex-direction: column;
    }
  }

  &__start {
    max-width: 60%;
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;

    @include mobile {
      max-width: 100%;
      margin-bottom: 32px;
    }
  }

  &__end {
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__title {
    font-size: 28px;
    font-weight: 800;
    line-height: 33px;
    color: #3b4256;

    @include mobile {
      font-size: 20px;
      text-align: center;
    }
  }

  &__subtitle {
    color: #3b4256;

    @include mobile {
      font-weight: 500;
      text-align: center;
      margin-top: 10px;
    }
  }

  &__menu {
    li {
      &:not(:last-child) {
        margin-bottom: 18px;
      }
    }

    a {
      width: 355px;
      display: block;
      font-size: 16px;
      line-height: 20px;
      color: #3b4256;
      font-weight: 500;
      padding-right: 20px;
      background-image: url(/images/right-chevron.svg);
      background-repeat: no-repeat;
      background-position: 100% center;
      transition: all 0.25s ease;

      &:hover {
        transform: scaleX(1.05);
      }

      @include tablet {
        background-image: none;
        width: unset;
      }

      @include mobile {
        font-size: 14px;
        line-height: 18px;
        text-align: right;
      }
    }
  }

  .container {
    @include tablet {
      padding: 0 15px;
    }
  }
}
</style>
