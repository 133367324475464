<template>
  <div class="main-page-data">
    <div class="main-page-data__top">
      <div class="main-page-data__title">{{ title }}</div>
      <div
        class="main-page-data__sorts"
        v-if="sortBy"
      >
        <div
          v-for="(sort, index) in sortBy"
          class="main-page-data__sort"
          :class="{ active: index === activeSort }"
          @click="$emit('change-active-sort', index)"
        >
          {{ sort.title }}
        </div>

        <m-dropdown
          v-if="false"
          class="mr-3"
          position="is-bottom-left"
        >
          <template #trigger="{ active }">
            <svg-icon name="dots" />
          </template>

          <b-dropdown-item aria-role="listitem">Действие 1</b-dropdown-item>
          <b-dropdown-item aria-role="listitem">Действие 2</b-dropdown-item>
          <m-separator />
          <b-dropdown-item aria-role="listitem">Еще действие</b-dropdown-item>
        </m-dropdown>
      </div>
    </div>

    <div class="main-page-data__main">
      <div class="main-page-data__start">
        <slot name="accordions" />
      </div>
      <div class="main-page-data__end">
        <div
          class="main-page-data__card"
          :class="{
            'no-card-padding': !cardPadding,
            empty,
          }"
        >
          <slot name="data">
            <div
              class="main-page-data__loading"
              :class="{ active: loading }"
            >
              Идет загрузка
            </div>
            <img
              v-if="!selectedWidget"
              class="main-page-data__choose-widget-img"
              src="/images/choose-widget.png"
              alt="Изображение виджета"
            />
            <m-rgis
              v-if="selectedWidget"
              :widgetId="selectedWidget"
              :key="selectedWidget"
              :preview="true"
            />
            <m-button
              class="main-page-data__fullscreen-button"
              icon="maximize"
              @click="$emit('open-widget')"
              v-if="selectedWidget"
            />
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainPageData',

  props: {
    title: {
      type: String,
      required: true,
    },
    sortBy: {
      type: Array,
    },
    activeSort: {
      type: Number,
    },
    cardPadding: {
      type: Boolean,
      default: true,
    },

    empty: {
      type: Boolean,
      default: false,
    },
    selectedWidget: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
.main-page-data {
  &__top {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #ededed;
    margin-bottom: 47px;

    @include mobile {
      flex-direction: column;
    }
  }

  &__title {
    font-size: 32px;
    line-height: 38px;
    font-weight: 800;
    color: #3b4256;

    @include mobile {
      margin-bottom: 15px;
    }
  }

  &__sorts {
    display: flex;
    height: 1em;
  }

  &__sort {
    position: relative;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 400;
    color: #848e99;
    margin-right: 20px;
    cursor: pointer;

    @include mobile {
      font-size: 12px;
    }

    &.active {
      color: #2f80ed;
      font-weight: 500;

      &:after {
        content: '';
        position: absolute;
        top: calc(100% + 28px);
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #2f80ed;

        @include mobile {
          top: calc(100% + 17px);
        }
      }
    }
  }

  &__main {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    @include tablet {
      flex-direction: column;
    }
  }

  &__start {
    width: calc(100% / 12 * 5);
    height: 570px;
    overflow: auto;

    @include tablet {
      width: 100%;
      height: unset;
      overflow: unset;
    }
  }

  &__end {
    position: relative;
    width: calc(100% / 12 * 7);
    height: 570px;
    max-height: unset;
    overflow: hidden;

    @include tablet {
      width: 100%;
      height: unset;
      overflow: unset;
    }
  }

  &__fullscreen-button {
    position: absolute;
    z-index: 600;
    top: 16px;
    right: 16px;
  }

  &__choose-widget-img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    max-width: unset;
  }

  &__card {
    padding: 36px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    min-height: 470px;
    border: 1px solid #ededed;
    background: $white;

    @include tablet {
      min-height: unset;
    }

    &.no-card-padding {
      padding: 0;
    }

    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__loading {
    opacity: 0;
    position: absolute;
    top: 5px;
    left: 50%;
    padding: 5px;
    font-weight: 500;
    transform: translate(-50%);
    background-color: rgba($white, 0.75);
    z-index: 999;
    pointer-events: none;
    transition: all 0.25s;

    &.active {
      opacity: 1;
    }
  }
}
</style>
