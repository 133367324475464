import axios from 'axios'

export default {
  namespaced: true,

  state: {
    categories: [],
    layers: [],
  },

  mutations: {
    setCategories: (state, value) => (state.categories = value),
    setLayers: (state, value) => (state.layers = value),
    setLayerActive: (state, id) => {
      const layer = state.layers.find(layer => layer['mapware.layers'] === id)
      if (layer) layer.active = true
    },
  },

  getters: {
    layersCats(state) {
      if (!state.categories.length) {
        return {}
      }
      return state.categories.reduce((acc, category) => {
        acc.push({
          ...category,
          childs: state.layers.filter(layer => layer['mapware.categories'] === category.id),
        })
        return acc
      }, [])
    },
  },

  actions: {
    async getCategories({ state, commit }) {
      await axios.get('/mapware/regions/23:items/mapware.categories.json').then(({ data }) => {
        commit(
          'setCategories',
          data.items.map(category => ({
            ...category,
            active: false,
            activeFilter: false,
            showInSearch: true,
          }))
        )
      })
    },

    async getLayers({ state, commit }) {
      await axios
        .get('/mapware/regions/23:items/mapware.layers/-view/item/-is_publish/1.json')
        .then(({ data }) => {
          commit(
            'setLayers',
            data.items.map(layer => ({
              ...layer,
              active: false,
              showInSearch: true,
            }))
          )
        })
    },
  },
}
