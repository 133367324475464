<template>
  <b-sidebar
    class="m-layers-sidebar"
    :class="{
      'is-left': !right,
      'is-right': right,
    }"
    type="is-light"
    fullheight
    :right="right"
    v-model="isOpened"
    :can-cancel="[]"
  >
    <m-button
      class="m-layers-sidebar__close-btn"
      @click="isOpened = false"
      :icon="right ? 'caret-right' : 'caret-left'"
    >
    </m-button>

    <div class="m-layers-sidebar__inner">
      <svg-icon
        name="arrow-left"
        class="m-item-detail__back-trigger mr-2 is-hidden-desktop"
        @click.native="isOpened = false"
      />
      <slot name="header" />

      <div class="m-layers-sidebar__tools mb-4">
        <div
          class="m-layers-sidebar__settings"
          @click="$emit('open-settings')"
        >
          Слои
          <svg-icon
            name="settings"
            class="ml-2"
          />
        </div>
        <div
          class="m-layers-sidebar__reset"
          @click="$emit('reset-settings')"
        >
          Сбросить настройки
        </div>
      </div>

      <div class="m-layers-sidebar__all-layers-toggle mb-3">
        <m-checkbox
          @input="toggleAllLayers"
          :value="isAllLayersVisible"
        >
          Показать все слои на карте
        </m-checkbox>
      </div>

      <div>
        <m-input
          class="m-layers-section__search-input mb-5"
          v-model="$searchString"
          placeholder="Поиск по слоям"
          icon-right="magnify"
          bg
          :autocomplete="autocomplete"
        />
      </div>

      <slot />
    </div>
  </b-sidebar>
</template>

<script>
import MCheckbox from '@/components/MCheckbox.vue'
import { mapState } from 'vuex'

export default {
  name: 'MLayersSidebar',

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    right: {
      type: Boolean,
      default: false,
    },

    searchString: {
      type: String,
      default: '',
    },
  },

  data: () => ({}),

  computed: {
    ...mapState('layers', ['mapLayers']),

    autocomplete() {
      return this.mapLayers
        .map(layer => layer.item.title)
        .filter(item => item.toLowerCase().includes(this.$searchString.toLowerCase()))
    },

    isAllLayersVisible() {
      return this.mapLayers.every(layer => layer.active)
    },

    isOpened: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },

    $searchString: {
      get() {
        return this.searchString
      },
      set(value) {
        this.$emit('search', value)
      },
    },
  },

  methods: {
    toggleAllLayers() {
      const toggleState = !this.isAllLayersVisible
      this.mapLayers.forEach(layer => {
        if (!layer.isError) {
          layer.active = toggleState
        }
      })
    },
  },

  watch: {
    value(value) {
      document.body.classList[value ? 'add' : 'remove']('layers-opened')
    },
  },

  components: {
    MCheckbox,
  },
}
</script>

<style lang="scss">
.m-layers-sidebar.b-sidebar {
  $self: '.m-layers-sidebar';
  position: relative;
  overflow: visible;

  &.is-left {
    #{$self}__close-btn {
      left: 100%;

      @include mobile {
        left: calc(100% - 100px);
      }
    }
  }

  .sidebar-content {
    overflow: visible;
    padding: 20px;
    width: 412px;
    background-color: $white;
    z-index: 900;
    max-width: 100vw;
    border: 1px solid $border-color;
  }

  #{$self}__close-btn {
    position: absolute;
    top: 20px;
    left: -48px;
    width: 40px;
    height: 48px;
    background: #f4f7fb;
  }

  // TODO: Кнопка справа

  #{$self}__inner {
    overflow: auto;
    height: 100%;
  }

  #{$self}__tools {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #{$self}__settings {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: $black;
    cursor: pointer;
    user-select: none;

    * {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  #{$self}__reset {
    cursor: pointer;
    user-select: none;
  }

  #{$self}__all-layers-toggle {
    .b-checkbox.checkbox input[type='checkbox'] + .check {
      width: 16px;
      height: 16px;
    }

    .control-label {
      color: $black;
    }
  }
}
</style>
