import Vuex from 'vuex'
import axios from 'axios'
import store from './index'

export default {
  namespaced: true,

  state: () => ({
    widgetId: '',
    token: '',
    baseLayers: [],
    bbox: '',
    center: [45.046526, 38.978311],
    loading: false,
    settings: {},
    title: '',
    url: '',
    widgetData: {},
    zoom: 15,
    headersLibrary: {},
  }),

  mutations: {
    setWidgetId: (state, value) => (state.widgetId = value),
    setToken: (state, value) => (state.token = value),
    setBaseLayers: (state, value) => (state.baseLayers = value),
    setBbox: (state, value) => (state.bbox = value),
    setCenter: (state, value) => (state.center = value),
    setLoading: (state, value) => (state.loading = value),
    setSettings: (state, value) => (state.settings = value),
    setTitle: (state, value) => (state.title = value),
    setUrl: (state, value) => (state.url = value),
    setWidgetData: (state, value) => (state.widgetData = value),
    setZoom: (state, value) => (state.zoom = value),
    setBaseLayer: (state, value) => (state.settings.map_base_layers = value),
  },

  getters: {
    mapUrls: state => state.settings?.map_base_layers,
    bounds: state => {
      const extent = state?.settings?.item?.extent

      if (extent) {
        const array = extent.split(',')
        const getExtent = item => item.split(' ').reverse()
        return [getExtent(array[0]), getExtent(array[1])]
      }

      return null
    },
  },

  actions: {
    async getSettings({ commit }) {
      await axios.get('/start/regions/23.json').then(({ data }) => {
        // Прежняя логика получения координат изначального центра карты
        // commit('setCenter', [...data?.center?.centroid?.coordinates]?.reverse())
        // Установка изначального центра карты в Краснодаре
        commit('setCenter', [45.046526, 38.978311])
        commit('setSettings', data)
      })
    },

    async getWidgetData({ state, commit, dispatch, rootState }) {
      await axios
        // .get(`/start/regions/23:items/apps_widgets/-apps_widgets/${state.widgetId}.json`, {
        // .get(`/start/apps_widgets/${state.widgetId}.json`, {
        .get(`/start/apps_widgets/${state.widgetId}.json`, {
          params: {
            uid: state.token,
          },
        })
        .then(({ data }) => {
          commit('setWidgetData', data)
          store.commit('layers/setUrls', data?.item.data_channel.split(';'))
          commit('setTitle', data?.item?.title)
          commit('setBaseLayers', data.map_base_layers)
          // TODO: fix dispatch
          store.dispatch('layers/getLayers')
        })
    },
  },
}
