<template>
  <div
    class="m-separator"
    :style="{
      marginLeft,
      marginRight,
      width,
    }"
  />
</template>

<script>
export default {
  name: 'MSeparator',

  props: {
    fluid: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    marginLeft() {
      return this.fluid ? `-${this.fluid}px` : 0
    },
    marginRight() {
      return this.fluid ? `${this.fluid}px` : 0
    },
    width() {
      return this.fluid ? `calc(100% + ${this.fluid * 2}px)` : '100%'
    },
  },
}
</script>

<style lang="scss">
.m-separator {
  width: 100%;
  height: 1px;
  background-color: $separator-color;
  margin: 5px 0;
}
</style>
