<template>
  <div class="index-page">
    <div class="container">
      <main-page-data
        v-if="iogv"
        class="mb-5"
        title="Публичные карты"
        :sort-by="iogvSorts"
        :active-sort="activeIogvSortIndex"
        @change-active-sort="activeIogvSortIndex = $event"
        @open-widget="openWidget"
        :card-padding="false"
        :selectedWidget="selectedWidget"
        :loading="loading"
      >
        <template #accordions>
          <div class="index-page__mpd">
            <div
              class="index-page__mpd-search mb-4"
              ref="mpdSearch"
            >
              <m-input
                icon-right="magnify"
                placeholder="Поиск"
                :autocomplete="filtersWidgetAutocomplete"
                v-model="filtersWidgetSearchString"
              />
            </div>
            <div class="index-page__mpd-accordions">
              <template
                v-for="(iogv, iogvIndex) in selectedSortType === 'iogv'
                  ? preparedIogvs
                  : preparedThemes"
              >
                <m-collapse
                  v-if="iogv.widgets && iogv.widgets.length"
                  :key="iogv.id"
                  :title="iogv.name"
                  :value="iogv.opened"
                >
                  <div
                    class="m-collapse__radio-button"
                    v-for="(widget, index) in iogv.widgets"
                    :key="widget.id + index"
                  >
                    <b-field>
                      <b-radio
                        v-model="selectedWidgetValue"
                        :native-value="`widget-${iogvIndex}-${index}`"
                        @input="selectedWidget = widget.id"
                      >
                        {{ widget.name }}
                      </b-radio>
                    </b-field>
                    <p
                      class="m-collapse__radio-description"
                      v-html="widget.desc"
                    ></p>
                  </div>
                </m-collapse>
              </template>
            </div>
          </div>
        </template>
      </main-page-data>

      <main-page-data
        v-if="mo"
        title="Услуги"
        :sort-by="moSorts"
        :active-sort="activeMOSortIndex"
        @change-active-sort="activeMOSortIndex = $event"
        :empty="!selectedService"
      >
        <template #accordions>
          <div class="index-page__mpd">
            <div class="index-page__mpd-search">
              <m-input
                icon-right="magnify"
                class="mb-4"
                placeholder="Поиск"
                :autocomplete="filtersMoAutocomplete"
                v-model="filtersMoSearchString"
              />
            </div>
            <div class="index-page__mpd-accordions">
              <template v-for="(mo, moIndex) in preparedMos">
                <m-collapse
                  v-if="mo.services && mo.services.length"
                  :key="mo.id"
                  :title="mo.name"
                  :value="mo.opened"
                >
                  <div
                    class="m-collapse__radio-button"
                    v-for="(service, index) in mo.services"
                    :key="`${service.id}-${moIndex}-${index}`"
                  >
                    <b-field>
                      <b-radio
                        v-model="selectedServiceValue"
                        :native-value="service.name"
                        @input="selectedService = service"
                      >
                        {{ service.name }}
                      </b-radio>
                    </b-field>
                    <p
                      class="m-collapse__radio-description"
                      v-html="service.desc"
                    ></p>
                  </div>
                </m-collapse>
              </template>
            </div>
          </div>
        </template>

        <template
          #data
          v-if="selectedService"
        >
          <div>
            <div class="m-collapse__data-title">{{ selectedService.name }}</div>
            <div
              v-if="false"
              class="m-collapse__data-terms"
            >
              2 - 4 раб. дн. • Платно
            </div>
            <div
              v-if="selectedService.full_desc"
              class="m-collapse__data-description"
              v-html="selectedService.full_desc"
            />
          </div>

          <div class="m-collapse__data-footer">
            <a
              :href="selectedService.link"
              target="_blank"
              class="m-collapse__data-gosuslugi"
            >
              <img
                src="/images/gosuslugi.svg"
                class="m-collapse__data-gosuslugi-logo"
                alt="Логотип Госуслуги"
              />
              <span>Заказать услугу</span>
              <svg-icon
                name="arrow-right"
                class="m-collapse__data-gosuslugi-arrow"
              />
            </a>
          </div>
        </template>
        <template
          #data
          v-else
        > <img
              v-if="!selectedWidget"
              class="main-page-data__choose-widget-img"
              src="/images/choose-service.png"
              alt="Выберите мунициальное образование из списка"
            />
          <strong>Выберите мунициальное образование из списка</strong>
        </template>
      </main-page-data>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import MainPageData from '@/components/MainPageData.vue'
import MCollapse from '@/components/MCollapse.vue'
import { mapState } from 'vuex'

export default {
  name: 'IndexPage',

  data: () => ({
    iogv: [],
    iogvSorts: [
      { title: 'По ИОГВ', type: 'iogv' },
      { title: 'По теме', type: 'theme' },
    ],
    activeIogvSortIndex: 0,
    selectedWidget: '',
    selectedWidgetValue: null,
    mo: null,
    moSorts: [{ title: 'По  МО' }],
    activeMOSortIndex: 0,
    selectedService: null,
    selectedServiceValue: null,
    services: null,
    theme: null,
    widgets: null,
    filtersWidgetSearchString: '',
    filtersMoSearchString: '',
  }),

  computed: {
    ...mapState('common', ['loading']),

    // loading() {
    //   return true
    // },

    selectedSortType() {
      return this.iogvSorts[this.activeIogvSortIndex]?.type
    },
    preparedIogvs() {
      const result = []
      this.iogv.forEach(iogv => {
        const element = {
          ...iogv,
          widgets: [],
          opened: false,
        }
        this.widgets.forEach(widget => {
          if (widget.iogv === iogv.id) {
            if (
              !this.filtersWidgetSearchString ||
              widget.name.toLowerCase().includes(this.filtersWidgetSearchString.toLowerCase())
            ) {
              element.widgets.push({
                ...widget,
              })
            }
          }
        })

        if (this.filtersWidgetSearchString.length && element.widgets?.length) {
          element.opened = true
        }

        if (element.widgets && element.widgets.length) {
          result.push(element)
        }
      })
      return result.filter(item => item.widgets)
    },

    preparedThemes() {
      const result = []
      this.theme.forEach(theme => {
        const element = {
          ...theme,
          widgets: [],
          opened: false,
        }
        this.widgets.forEach(widget => {
          widget.theme.forEach(wTheme => {
            if (wTheme === theme.id) {
              if (
                !this.filtersWidgetSearchString ||
                widget.name.toLowerCase().includes(this.filtersWidgetSearchString.toLowerCase())
              ) {
                element.widgets.push({
                  ...widget,
                })
              }
            }
          })
        })

        if (this.filtersWidgetSearchString.length && element.widgets?.length) {
          element.opened = true
        }

        result.push(element)
      })
      return result
    },

    preparedMos() {
      const result = []
      this.mo.forEach(mo => {
        const element = {
          ...mo,
          services: [],
          opened: false,
        }
        this.services.forEach(widget => {
          if (widget.mo === mo.id) {
            if (
              !this.filtersMoSearchString ||
              widget.name.toLowerCase().includes(this.filtersMoSearchString.toLowerCase())
            ) {
              element.services.push({
                ...widget,
              })
            }
          }
        })

        if (this.filtersMoSearchString.length && element.services?.length) {
          element.opened = true
        }

        if (!this.filtersMoSearchString || (element.services && element.services.length)) {
          result.push(element)
        }
      })
      return result.filter(item => item.services)
    },

    filtersWidgetAutocomplete() {
      if (!this.widgets) return []
      return this.widgets
        .map(widget => widget.name)
        .filter(item => item.toLowerCase().includes(this.filtersWidgetSearchString.toLowerCase()))
    },

    filtersMoAutocomplete() {
      if (!this.services) return []
      return this.services
        .map(service => service.name)
        .filter(item => item.toLowerCase().includes(this.filtersMoSearchString.toLowerCase()))
    },
  },

  created() {
    this.getData()
  },

  mounted() {
    this.$nextTick(() => {
      const { height } = this.$refs.mpdSearch.getBoundingClientRect()
      document.documentElement.style.setProperty('--mpd-search-height', `${height}px`)
    })
  },

  methods: {
    getData() {
      this.iogv = []

      axios
        .get('/api/main.json')
        .then(({ data }) => {
          Object.keys(data).forEach(key => (this[key] = data[key]))

          this.$store.commit('main/setTopLinks', [...data.top_links])
          this.$store.commit('main/setBottomLinks', [...data.bottom_links])
        })
        .then(() => {
          this.iogv.forEach(iogv => {
            this.widgets.forEach(widget => {
              if (widget.iogv === iogv.id) {
                if (!iogv.widgets) {
                  iogv.widgets = []
                } else {
                  iogv.widgets.push({
                    ...widget,
                  })
                }
              }
            })
          })
        })
    },

    openWidget() {
      this.$router.push({
        name: 'Map',
        params: {
          widgetId: this.selectedWidget,
        },
      })
    },
  },

  components: {
    MainPageData,
    MCollapse,
  },
}
</script>

<style lang="scss">
.index-page {
  padding: 80px 0 120px;

  .m-collapse {
    padding: 13px 16px;
    background-color: $bg;
    color: $black;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &__trigger-header {
      &[aria-expanded] {
        position: relative;
        padding-bottom: 13px;
        margin-bottom: 12px;

        &:after {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          height: 1px;
          width: 100%;
          background: #dddee2;
        }
      }
    }

    .card-header-title {
      font-size: 16px;
      line-height: 1.4;
      user-select: none;
    }

    &__radio {
      margin-bottom: 8px;
    }

    &__radio-button {
      display: block;
      font-size: 14px;
      line-height: 1.4;
      margin-bottom: 8px;

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      .field {
        margin-bottom: 0;
      }

      input {
        margin-right: 9px;

        &[type='radio']:checked {
          + .check {
            border-color: #4f4f4f;

            &:before {
              background-color: #4f4f4f;
            }
          }
        }
      }
    }

    &__radio-description {
      font-size: 12px;
      line-height: 1.4;
    }

    &__data {
      &-title {
        font-size: 20px;
        line-height: 24px;
        color: #252525;
        font-weight: 700;
        margin-bottom: 16px;
      }

      &-terms {
        font-weight: 500;
        color: #2f80ed;
        margin-bottom: 16px;
      }

      &-description {
        font-size: 16px;
        font-weight: 40;
        line-height: 1.4;
        color: #252525;

        p {
          margin-bottom: 12px;
        }
      }

      &-footer {
        padding-top: 16px;
        border-top: 1px solid #d7d8db;
        margin-top: 50px;
      }

      &-gosuslugi {
        position: relative;
        display: flex;
        align-items: center;
        color: #252525;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        line-height: 1.4;

        &-logo {
          width: 28px;
          height: 28px;
          margin-right: 12px;
        }

        &-arrow {
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
        }
      }
    }
  }

  &__mpd {
    height: 100%;

    &-accordions {
      height: calc(100% - 16px - var(--mpd-search-height));
      overflow: auto;
    }
  }

  .container {
    @include tablet {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
</style>
