<template>
  <div class="RGIS-map-widget">
    <Map
      @open-layers="isLayersSidebarOpened = true"
      @open-table="isTableOpened = true"
      @open-search-panel="isSearchOpened = true"
      @show-detail="showDetailHandler"
      :preview="preview"
      :title="title"
    />

    <!-- LAYERS SIDEBAR -->
    <m-layers-sidebar
      v-if="!preview"
      v-model="isLayersSidebarOpened"
      :search-string="layersSearchString"
      right
      @search="layersSearchString = $event"
      @open-settings="openCategoriesSettingsHandler"
    >
      <template #header>
        <m-select
          v-model="selectedMapUrl"
          label="Тип карты"
          :options="urls"
          fluid
        />
        <m-separator class="mt-4 mb-4" />
      </template>

      <b-loading
        v-if="loading"
        v-model="loading"
      />

      <template v-else>
        <m-layers-section
          v-for="(group, index) in groupedMapLayers"
          :key="index"
          :items="group.items"
          :title="group.title"
          :open-all="!!layersSearchString.length"
        />
      </template>
    </m-layers-sidebar>

    <!-- SEARCH PANEL -->
    <div
      v-if="!preview"
      class="search-panel-trigger"
      :class="{ 'dropdown-opened': isFiltersDropdownOpened }"
    >
      <b-dropdown
        @active-change="isFiltersDropdownOpened = $event"
        ref="searchPanelDropdown"
        :close-on-click="false"
        :can-close="false"
        :value="isFiltersDropdownOpened"
      >
        <template #trigger>
          <m-input
            v-model="searchString"
            class="m-input"
            placeholder="Поиск"
            icon-right="magnify"
            @input="showSearchPanel"
          />
          <!-- @click.native="isSearchOpened = true" -->
        </template>
        <b-dropdown-item
          :focusable="true"
          custom
          paddingless
        >
          <m-filter
            @open-settings="isFiltersModalOpened = true"
            :dropdown="$refs.searchPanelDropdown"
            ref="mFilter"
          />
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <m-sidebar
      v-if="!preview"
      class="search-panel"
      v-model="isSearchOpened"
      back-button
    >
      <div class="is-flex">
        <svg-icon
          name="arrow-left"
          class="m-item-detail__back-trigger mr-2 is-hidden-desktop"
          @click.native="isSearchOpened = false"
        />
        <h3
          v-if="searchString"
          class="search-panel-result-title mb-4"
        >
          Результаты поиска ({{ searchVariants ? searchVariants.length : 0 }})
        </h3>
        <h3
          v-else-if="searchString.length"
          class="search-panel-result-title mb-4"
        >
          Ничего не найдено
        </h3>
      </div>
      <template v-if="searchString">
        <m-search-result-item
          v-for="item in searchVariants"
          :key="item.id"
          :item="item"
          @click.native="openFeatureDetail(item)"
        />
      </template>
    </m-sidebar>

    <!-- OBJECT DETAIL PANEL -->
    <m-sidebar
      class="object-info-sidebar"
      v-model="isLayerInfoOpened"
    >
      <m-item-detail
        :error="detailError"
        :selectedLayer="selectedLayer"
        :selectedLayerData="selectedLayerData"
        @go-back="isLayerInfoOpened = false"
      >
      </m-item-detail>
    </m-sidebar>

    <m-main-table
      v-if="!preview"
      :active="isTableOpened"
      @close="isTableOpened = false"
    />

    <b-modal
      v-model="isLayersModalOpened"
      width="500px"
      custom-class="layers-config-modal"
    >
      <div class="card">
        <header class="modal-card-head">
          <p class="modal-card-title">Настройка слоёв</p>
          <svg-icon
            name="cross"
            class="cursor-pointer"
            @click.native="cancelSelectCategories"
          />
        </header>

        <div class="card-content">
          <m-input
            placeholder="Поиск по слоям"
            :autocomplete="layersConfigAutocomplete"
            v-model="layersConfigSearchString"
          />
          <m-select-layer-sections
            v-if="layersCats && layersCats.length"
            :items="layersCats"
            :open-all="!!layersConfigSearchString.length"
          />
        </div>

        <footer class="modal-card-footer has-text-centered p-4">
          <m-button
            v-if="false"
            class="m-sidebar__close-btn mr-3"
            @click.native="cancelSelectCategories"
          >
            Отмена
          </m-button>
          <m-button
            class="m-sidebar__close-btn"
            @click.native="applySelectCategories"
            type="primary"
          >
            Применить
          </m-button>
        </footer>
      </div>
    </b-modal>

    <b-modal
      v-model="isFiltersModalOpened"
      width="500px"
      custom-class="layers-config-modal"
    >
      <div class="card">
        <header class="modal-card-head">
          <p class="modal-card-title">Настройка фильтров</p>
          <svg-icon
            name="cross"
            class="cursor-pointer"
            @click.native="isFiltersModalOpened = false"
          />
        </header>

        <div class="card-content">
          <m-input
            placeholder="Поиск по слоям"
            :autocomplete="filtersConfigAutocomplete"
            v-model="filtersConfigSearchString"
          />
          <m-select-filters
            v-if="layersCats && layersCats.length"
            :openAll="!!filtersConfigSearchString.length"
          />
        </div>

        <footer
          class="modal-card-footer has-text-centered p-4"
          v-if="false"
        >
          <m-button
            v-if="false"
            class="m-sidebar__close-btn mr-3"
            @click.native="isFiltersModalOpened = false"
          >
            Отмена
          </m-button>
          <m-button
            class="m-sidebar__close-btn"
            @click.native="isFiltersModalOpened = false"
            type="primary"
          >
            Применить
          </m-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import draggable from 'vuedraggable'

import MLayersSidebar from '@/components/MLayersSidebar.vue'
import MSidebar from '@/components/MSidebar.vue'
import MMainTable from '@/components/MMainTable.vue'
import Map from '@/components/Map.vue'
import MSeparator from '@/components/MSeparator.vue'
import MSelectLayerSections from '@/components/MSelectLayerSections.vue'
import MSelectFilters from '@/components/MSelectFilters.vue'
import MLayersSection from '@/components/MLayersSection.vue'
import MSearchResultItem from '@/components/MSearchResultItem.vue'
import MItemDetail from '@/components/MItemDetail.vue'
import MFilter from '@/components/MFilter.vue'
import token from '@/data/api-key'

export default {
  name: 'RGIS',

  props: {
    widgetId: {
      type: String,
      required: true,
    },
    preview: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    controls: true,
    isLayersSidebarOpened: false,
    isFiltersDropdownOpened: false,
    isFiltersModalOpened: false,
    isLayersModalOpened: false,
    isLayerInfoOpened: false,
    isSearchOpened: false,
    isTableOpened: false,
    selectedMapUrl: '',
    searchData: [],
    mapTypeOptions: [
      { id: 1, text: 'Схема' },
      { id: 2, text: 'Спутник' },
    ],
    detailError: '',
    layersSearchString: '',
    layersConfigSearchString: '',
    filtersConfigSearchString: '',
  }),

  computed: {
    ...mapState('layers', ['mapLayers', 'selectedLayer']),
    ...mapState('common', ['settings', 'loading', 'title']),
    ...mapState('dictionary', {
      allCategories: 'categories',
      allLayers: 'layers',
    }),

    ...mapGetters('layers', [
      'groupedMapLayers',
      'selectedLayerData',
      'allFeatures',
      'activeMapLayers',
    ]),
    ...mapGetters('common', ['mapUrls']),
    ...mapGetters('search', ['searchVariants']),
    ...mapGetters('dictionary', ['layersCats']),

    layersConfigAutocomplete() {
      return this.allLayers
        .map(layer => layer.title)
        .filter(item => item.toLowerCase().includes(this.layersConfigSearchString.toLowerCase()))
    },

    filtersConfigAutocomplete() {
      return this.activeMapLayers
        .map(layer => layer.item.title)
        .filter(item => item.toLowerCase().includes(this.filtersConfigSearchString.toLowerCase()))
    },

    urls() {
      return this.mapUrls?.map(item => ({
        ...item,
        text: item.title,
        id: item.url,
      }))
    },

    searchString: {
      get() {
        return this.$store.state.search.searchString
      },
      set(value) {
        this.$store.commit('search/setSearchString', value)
      },
    },
  },

  created() {
    this.$store.commit('common/setWidgetId', this.$route.params.widgetId || this.widgetId)
    this.$store.commit('common/setToken', this.$route.query.token || token)

    this.$store.dispatch('common/getSettings').then(async () => {
      await this.$store.dispatch('dictionary/getCategories')
      await this.$store.dispatch('dictionary/getLayers')
      this.$store.commit('common/setUrl', this.mapUrls?.[0]?.url)
      this.selectedMapUrl = this.mapUrls?.[0]?.url
      this.$store.dispatch('common/getWidgetData')
    })
  },

  methods: {
    showDetailHandler(success = true) {
      if (success) {
        this.detailError = ''
      } else {
        this.detailError = 'Данные не найдены'
      }
      this.isLayerInfoOpened = true
    },

    showSearchPanel() {
      if (this.$refs.mFilter.state) {
        this.$refs.searchPanelDropdown.toggle()
      }
      this.isFiltersDropdownOpened = false
      this.isLayerInfoOpened = false
      this.isSearchOpened = true
    },

    openFeatureDetail(feature) {
      const { layerId, coordinates } = feature
      const featureId = feature.id

      this.$store
        .dispatch('layers/getObjectData', {
          feature,
          layerId,
          featureId,
        })
        .then(() => {
          this.$root.$emit('open-detail', {
            id: featureId,
            lng: feature.geometry.coordinates?.[0],
            lat: feature.geometry.coordinates?.[1],
            feature: feature,
            featureId: feature.id,
            layerId: feature.layerId,
          })
          // this.isSearchOpened = false
          this.showDetailHandler(true)
          setTimeout(() => {
            this.$nextTick(() => {
              const element = document.querySelector(
                `.map__marker.map__marker--${feature.id || feature.properties?.['dinv.objects2']}`
              )
              document
                .querySelectorAll('.map__marker.map__marker--active')
                .forEach(marker => marker.classList.remove('map__marker--active'))

              if (element) {
                element.classList.add('map__marker--active')
              }
            })
          }, 500)
        })
        .catch(error => {
          this.showDetailHandler(true)
        })
        .finally(() => {
          if (coordinates) {
            this.$store.commit('setCenter', coordinates)
            this.$store.commit('setZoom', 16)
          }
        })
    },

    openCategoriesSettingsHandler() {
      this.isLayersModalOpened = true
    },

    async applySelectCategories() {
      // TODO: доработать выбор категорий
      const enabledLayers = this.layersCats.reduce((acc, item) => {
        acc.push(...item.childs.filter(child => child.active))
        return acc
      }, [])
      const urls = enabledLayers.map(item => item.u)
      this.$store.commit('layers/setUrls', urls)
      this.$store.dispatch('layers/getLayers', false)
      this.isLayersModalOpened = false
    },

    cancelSelectCategories() {
      this.isLayersModalOpened = false
    },
  },

  watch: {
    /*widgetId: function(oldVal, newVal) {
      console.log('widgetId: ', newVal);
      //this.created();
      this.$store.commit('common/setWidgetId', this.$route.params.widgetId || newVal)
      this.$store.commit('common/setToken', this.$route.query.token || token)

      this.$store.dispatch('common/getSettings').then(async () => {
        await this.$store.dispatch('dictionary/getCategories')
        await this.$store.dispatch('dictionary/getLayers')
        this.$store.commit('common/setUrl', this.mapUrls?.[0]?.url)
        this.selectedMapUrl = this.mapUrls?.[0]?.url
        this.$store.dispatch('common/getWidgetData')
      });

    },*/

    selectedMapUrl(value) {
      this.$store.commit('common/setUrl', value)
    },

    isSearchOpened(value) {
      if (value) {
        this.$nextTick(() => {
          // this.$refs.searchTagInput.focus()
        })
      }
    },

    layersSearchString(value) {
      let searched = 0
      this.mapLayers.forEach(layer => {
        if (layer.item?.title?.toLowerCase().includes(value.toLowerCase())) {
          searched++
          layer.showInSearch = true
        } else {
          layer.showInSearch = false
        }
      })
    },

    isSearchOpened(value) {
      document.body.classList[value ? 'add' : 'remove']('search-opened')
    },

    // allCategories: {
    //   deep: true,
    //   handler(value) {
    //     // this.allCategories = JSON.parse(JSON.stringify(value))?.items
    //   },
    // },

    layersConfigSearchString(value) {
      this.allLayers.forEach(layer => {
        layer.showInSearch = layer.title.toLowerCase().includes(value.toLowerCase())
      })
    },

    filtersConfigSearchString(value) {
      this.mapLayers.forEach(layer => {
        layer.showInSearch = layer.item.title.toLowerCase().includes(value.toLowerCase())
      })
    },
  },

  components: {
    draggable,
    MLayersSidebar,
    MSidebar,
    Map,
    MMainTable,
    MSeparator,
    MLayersSection,
    MSearchResultItem,
    MItemDetail,
    MFilter,
    MSelectLayerSections,
    MSelectFilters,
  },
}
</script>

<style lang="scss">
$tools: 'zoom', 'search', 'location', 'table', 'layers', 'config', 'ruler', 'print', 'share';

.RGIS-map-widget {
  width: 100%;
  height: 100%;
  position: relative;

  .row {
    gap: 16px;
  }

  .col {
    padding: 20px;
  }

  .drag-wrapper {
    background: #f4f7fb;
    border: 1px solid #dddee2;
    border-radius: 8px;
    height: 100%;
  }
}

.leaflet-control-zoom {
  display: none;
}

.leaflet-control-attribution.leaflet-control {
  display: none;
}

.m-sidebar.object-info-sidebar {
  .sidebar-content {
    padding-top: 75px;
    min-width: 450px;
    max-width: 100vw;

    @include mobile {
      min-width: unset;
    }
  }
}

.m-sidebar.search-panel {
  .sidebar-content {
    padding-top: 75px;

    min-width: 460px;
    max-width: 100vw;

    @include mobile {
      min-width: unset;
    }
  }

  .dropdown-menu {
    position: relative;
    z-index: 1500;
  }

  .search-panel-result-title {
    font-size: 20px;
    line-height: 140%;
    font-style: italic;
    color: $black;
  }
}

.search-panel-trigger {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 420px;
  max-width: calc(100vw - 40px);
  z-index: 800;

  &.dropdown-opened {
    width: 460px;
    max-width: calc(100vw - 40px);
    top: 0;
    left: 0;
    padding: 20px;
    background-color: $white;
    box-shadow: 3px 3px 1px 0 rgb(10 10 10 / 10%), 0 1px 0 1px rgb(10 10 10 / 2%);
  }

  .dropdown {
    width: 100%;

    &-trigger {
      width: 100%;
    }

    &-menu {
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-right: -20px;
    }

    &-content {
      padding: 0;
      box-shadow: 3px 3px 1px 0 rgb(10 10 10 / 10%), 0 1px 0 1px rgb(10 10 10 / 2%);
    }
  }
}
</style>
